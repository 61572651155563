<template>
  <div class="class-report-wrap" v-if="detail">
    <div class="summary">
      <div>
        <div class="value">{{ toStartedTime(detail.started_at) }}</div>
        <div class="unit">{{ detail.name }}</div>
      </div>
      <div>
        <div class="value">{{ detail.details.length + '명' }}</div>
        <div class="unit">{{ $t('참여인원') }}</div>
      </div>
      <div>
        <div class="value">{{ toRunningTime(detail.running_time) }}</div>
        <div class="unit">{{ $t('운동시간') }}</div>
      </div>
      <div>
        <div class="value">{{ Math.round(detail.avg_hr) }}</div>
        <div class="unit">{{ $t('평균심박(BPM)') }}</div>
      </div>
      <div>
        <div class="value">{{ Math.round(detail.avg_calories/3) }}</div>
        <div class="unit">{{ $t('평균 칼로리(Kcal)') }}</div>
      </div>
    </div>

    <el-collapse ref="collapse" v-model="activeNames" @change="onChangeCollapse">
      <el-collapse-item
        v-for="(d, i) in detail.details"
        :key="d.id"
        @click="updateDetail(d, i)"
        :name="i"
      >
        <template slot="title">
          <div class="item-title">{{ d.name }}</div>
          <div class="actions">
            <slot name="actions" />
            <el-button v-if="activeNames.includes(i) && !d.loading" @click.stop="onDownloadExcel(d, i)" class="default"
              ><span>다운로드</span><span class="icon el-icon-download" />
            </el-button>
            <el-button @click.stop="onDelete(d, i)" class="default"
              ><span>삭제</span><span class="icon el-icon-delete" />
            </el-button>
          </div>
        </template>
        <div ref="reportContainer">
          <Report :detail="d" :show-title="false" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import html2canvas from 'html2canvas';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Report from '@/components/Report'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ClassReport',
  components: { Report },
  mixins: [MixinFormatUtils],
  props: ['detail'],
  computed: {
    ...mapGetters(['user']),
    chartData() {
      return {
        label: this.ranges.map(i => i.range),
        color: this.ranges.map(i => i.color),
        time: this.ranges.map((_, i) => this.detail[`range${i + 1}_time`])
      }
    }
  },
  data() {
    return {
      goal_type: {
        1: `0~59% ${this.$t('WARMING_UP')}`,
        2: `60~69% ${this.$t('FAT_BURNING')}`,
        3: `70~79% ${this.$t('IMPROVE_ENDURANCE')}`,
        4: `80~89% ${this.$t('MAX_EXERCISE_EFFECT')}`,
        5: `90~100% ${this.$t('MAX_STRANGTH')}`
      },
      activeNames: [] // 활성화된 항목을 관리하기 위한 배열
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async updateDetail(detail, index) {
      window.console.log(index)
    },
    hexToArgb(hex) {
      return `FF${hex.replace('#', '')}`;
    },
    getAge(birthDay) {
      return (
        moment()
          .startOf('year')
          .diff(moment(birthDay).startOf('year'), 'years') +
        1
      )
    },
    async onDownloadExcel(d, i) {
      // Create a new workbook and add the image
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('개인운동이력(운동정보)');

      // 열 너비 설정
      ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'].forEach((col) => {
        worksheet.getColumn(col).width = 25.25;
      });
      ['J', 'K', 'L', 'M', 'N'].forEach((col) => {
        worksheet.getColumn(col).width = 19;
      });
      // 행 높이 설정
      for (let row = 2; row <= 8; row++) {
        worksheet.getRow(row).height = 42.5;
      }
      
      const endOfRowNum = 11 + d.hrs.time.length;
      for (let row = 10; row <= endOfRowNum; row++) {
        worksheet.getRow(row).height = 32.5;
      }

      // 헬퍼 함수: 셀 스타일 설정
      const setCellStyle = (cell, options = {}) => {
        cell.font = { size: 15, ...options.font };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true, ...options.alignment };
        if (options.fill) {
          cell.fill = options.fill;
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      };

      // 개인정보 섹션
      const personalInfoTitleCell = worksheet.getCell('B2');
      personalInfoTitleCell.value = '개인정보';
      setCellStyle(personalInfoTitleCell, {
        font: { color: { argb: 'FFFFFF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } }
      });
      const bgTitle = 'F2F2F2';
      const personalInfoHeaders = ['이름', '성별', '생년월일', '나이(year)', '신장(cm)', '체중(kg)', '안정 시 심박수(bpm)', '최대심박수(bpm)'];
      personalInfoHeaders.forEach((header, index) => {
        const cell = worksheet.getCell(3, index + 2);
        cell.value = header;
        setCellStyle(cell, {
          font: { bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgTitle } }
        });
      });

      // 회원정보 API 호출
      const personalInfoValues = [
        d.member.name,
        d.member.gender === 0 ? '남' : '여',
        d.member.birth_day,
        this.getAge(d.member.birth_day),
        d.member.height,
        d.member.weight,
        d.member.optimal_hr,
        d.member.max_hr === null || d.member.max_hr === 0 ? this.getMaxHr(d.member.birth_day) : d.member.max_hr
      ];
      personalInfoValues.forEach((value, index) => {
        const cell = worksheet.getCell(4, index + 2);
        cell.value = value;
        setCellStyle(cell);
      });

      // 운동이력 섹션
      const exerciseTitleCell = worksheet.getCell('B6');
      exerciseTitleCell.value = '운동이력';
      setCellStyle(exerciseTitleCell, {
        font: { color: { argb: 'FFFFFF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } }
      });

      worksheet.mergeCells('J6:N6');
      const exerciseIntensityCell = worksheet.getCell('J6');
      exerciseIntensityCell.value = '운동강도(%)';
      setCellStyle(exerciseIntensityCell, {
        font: { bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgTitle } }
      });

      const exerciseHeaders = [
        'No', '날짜', '운동시간', '클래스명', '소비칼로리(kcal)', '평균심박수(bpm)', '최고심박수(bpm)', '평균 운동강도(%)',
        '운동강도\r\n0~59(%)', '운동강도\r\n60~69(%)', '운동강도\r\n70~83(%)', '운동강도\r\n84~91(%)', '운동강도\r\n92~100(%)'
      ];
      var colorMap = [this.user.shop.level1_color, this.user.shop.level2_color, this.user.shop.level3_color, this.user.shop.level4_color, this.user.shop.level5_color]
      console.log("colorMap : ", colorMap);
      // ['A9A9A9', '87CEEB', '90EE90', 'FFA07A', 'FF6347']
      exerciseHeaders.forEach((header, index) => {
        const cell = worksheet.getCell(7, index + 2);
        cell.value = header;
        setCellStyle(cell, {
          font: { color: { argb: index < 8 ? '000000' : 'FFFFFF' }, bold: true },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: index < 8 ? bgTitle : this.hexToArgb(colorMap[index - 8]) }
          }
        });
      });

      console.log(d);
      const exerciseValues = [
        1, // No
        this.toStartedTimeFull(d.started_at), // 날짜
        this.toRunningTime_3(d.running_time), // 운동시간
        d.class_name, // 클래스명
        Math.round(d.calories/3), // 소비칼로리(kcal)
        Math.round(d.avg_hr), // 평균심박수(bpm)
        d.max_hr, // 최고심박수(bpm)
        d.avg_per, // 평균 운동강도(%)
        Math.round(d.range1_time / d.running_time * 100), // 운동강도 0~59(%)
        Math.round(d.range2_time / d.running_time * 100), // 운동강도 60~69(%)
        Math.round(d.range3_time / d.running_time * 100), // 운동강도 70~83(%)
        Math.round(d.range4_time / d.running_time * 100), // 운동강도 84~91(%)
        Math.round(d.range5_time / d.running_time * 100) // 운동강도 92~100(%)
      ];
      exerciseValues.forEach((value, index) => {
        const cell = worksheet.getCell(8, index + 2);
        cell.value = value;
        setCellStyle(cell, {
          font: { color: { argb: index < 8 ? '000000' : 'FFFFFF' } },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: index < 8 ? '' : this.hexToArgb(colorMap[index - 8]) }
          }
        });
      });

      const exerciseInfoCell = worksheet.getCell('B10');
      exerciseInfoCell.value = '운동이력';
      setCellStyle(exerciseInfoCell, {
        font: { color: { argb: 'FFFFFF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } }
      });

      const exerciseInfoHeaders = ['운동시간', '심박수(bpm)', '운동강도(%)'];
      exerciseInfoHeaders.forEach((header, index) => {
        const cell = worksheet.getCell(11, index + 2);
        cell.value = header;
        setCellStyle(cell, {
          font: { bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgTitle } }
        });
      });

      const startTime = new Date(d.hrs.time[0]).getTime();
      const exerciseInfoValues = d.hrs.time.map((time, index) => {
        const elapsedTime = new Date(new Date(time).getTime() - startTime).toISOString().substr(11, 8); // HH:mm:ss 포맷
        return [
          elapsedTime,
          d.hrs.hr[index],
          { formula: `C${index + 12}/$I$4*100` } // 운동강도(%) 수식 추가
        ];
      });
      exerciseInfoValues.forEach((values, index) => {
        values.forEach((value, colIndex) => {
          const cell = worksheet.getCell(index + 12, colIndex + 2);
          if (typeof value === 'object' && value.formula) {
            cell.value = { formula: value.formula };
            cell.numFmt = '0.0'; // 소수점 첫째 자리까지만 표시
          } else {
            cell.value = value;
          }
          setCellStyle(cell);
        });
      });


      // Capture the visible content as an image
      const reportContainer = this.$refs.reportContainer[i];
      const canvas = await html2canvas(reportContainer);
      const imgData = canvas.toDataURL('image/png');

      // Add the image to the worksheet
      const imageId = workbook.addImage({
        base64: imgData,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: { col: 5, row: 11 },
        ext: { width: canvas.width, height: canvas.height },
      });

      // 기본 줌 설정
      worksheet.views = [{ state: 'normal', zoomScale: 70 }];

      // Generate Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, `${d.name || 'download'}.xlsx`);
    },
    async fetchMemberInfo(memberId) {
      // 회원정보 API 호출
      const response = await this.$apiServer.get(`api/v1/member/${memberId}/info.json`);
      return response.data;
    },
    onDelete(d, index) {
      this.$confirm(this.$t('선택한 이력을 정말 삭제 하시겠습니까?'), '운동이력 삭제', {
        confirmButtonText: this.$t('삭제'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true
      }).then(() => {
        let memberId = (typeof d.member === 'object' && d.member !== null && 'id' in d.member) ? d.member.id : d.member;
        this.$apiServer
          .delete(`api/v1/history/${d.workout_class_execution}/member/${memberId}/delm.json`)
          .then(() => {
            this.$message({
              type: 'success',
              message: this.$t('대상 이력이 삭제되었습니다.')
            })
            this.detail.details.splice(index, 1)
            this.$forceUpdate()
          })
      })
    },
    onChangeCollapse(names) {
      names.forEach(n => {
        if (this.detail.details[n].hrs === undefined) {
          this.$set(this.detail.details[n], 'loading', true); // 로딩 상태 추가
          console.log(`Loading started for detail ${n}`, this.detail.details[n]);
          this.detail.details[n].hrs = null
          const d = this.detail.details[n]
          this.$apiServer
              .get(`api/v1/history/${d.workout_class_execution}/member/${d.member}/hr.json`)
              .then(response => {
                const data = response.data;
                // 중복 제거 로직 추가
                console.log("중복제거전 개수 : ", data?.hrs?.hr?.length);
                const uniqueData = this.removeDuplicates(data.hrs);
                data.hrs = uniqueData;
                console.log("중복제거후 개수 : ", data?.hrs?.hr?.length);
                this.detail.details[n] = data;
                //this.$set(this.detail.details[n], 'hrs', data.hrs); // hrs 속성 업데이트
                this.$set(this.detail.details[n], 'loading', false); // 로딩 완료
                console.log(`Loading finished for detail ${n}`, this.detail.details[n]);
                this.$forceUpdate();
              })
        }
      })
    },
    removeDuplicates(hrs) {
      const seen = new Set();
      const uniqueHrs = {
        time: [],
        hr: []
      };

      // hrs?.time?.forEach((time, index) => {
      //   const hr = hrs.hr[index];
      //   const key = `${time}-${hr}`;
      //   if (!seen.has(key)) {
      //     seen.add(key);
      //     uniqueHrs.time.push(time);
      //     uniqueHrs.hr.push(hr);
      //   }
      // });
      hrs?.time?.forEach((time, index) => {
        const hr = hrs.hr[index];
        const timeInSeconds = Math.floor(new Date(time).getTime() / 1000); // 초 단위로 변환
        const key = `${timeInSeconds}`;
        if (!seen.has(key)) {
          seen.add(key);
          uniqueHrs.time.push(new Date(timeInSeconds * 1000)); // 초 단위로 변환된 시간값을 다시 Date 객체로 변환하여 추가
          uniqueHrs.hr.push(hr);
        }
      });

      return uniqueHrs;
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.class-report-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.summary {
  background-color: #555555;
  padding: 30px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  color: white;
  flex: 1;

  > div {
    text-align: center;
    flex: 1;
    flex-basis: 1px;

    > .unit {
      font-size: 16px;
    }

    > .value {
      margin-bottom: 5px;
      font-size: 20px;
    }
  }
}

.item-title {
  padding: 0 30px;
  font-size: 20px;
}
.el-collapse {
  border-top: none;
  border-bottom: none;
  .el-collapse-item {
    border-radius: 10px;
    border: solid 1px $border-grey;
    margin-bottom: 15px;
    overflow: hidden;
  }
  ::v-deep {
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border-bottom: none;
    }
  }
}
</style>
