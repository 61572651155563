<template>
  <div class="detail">
    <div class="title-wrapper clearfix">
      <div class="title">
        <slot name="header" :form="form" v-if="formType === 'show'" />
        <slot name="new" v-else-if="formType === 'new'" />
        <slot name="empty" v-else />
      </div>
      <div class="actions" v-if="formType === 'show'">
        <slot name="actions" />
        <el-button @click="onDownloadExcel" v-if="resource == 'members'" class="default">
          <span>다운로드</span><span class="icon el-icon-download" />
        </el-button>
        <el-button @click="onEdit" class="default">
          <span>수정</span><span class="icon el-icon-setting" />
        </el-button>
        <el-button @click="onDelete" class="default">
          <span>삭제</span><span class="icon el-icon-delete" />
        </el-button>
      </div>
      <el-button v-if="formType === 'new'" @click="onBulkRegister" class="default bulk-register-button">
        <span>일괄등록으로 변경</span>
      </el-button>
    </div>

    <div v-if="$scopedSlots.show && formType === 'show'">
      <slot name="show" :form="form" />
    </div>
    <div v-else-if="formType" class="form" :key="id || formType">
      <el-form
        label-position="left"
        label-width="150px"
        :disabled="readOnly"
        :class="{ show: readOnly }"
      >
        <el-form-item
          :label="f.label"
          v-for="(f, key) in formSchema"
          :key="key"
          :error="error[key]"
        >
          <el-input
            :is="formatToComponent(key, f)"
            :option="f.option"
            v-model="form[key]"
            :disabled="readOnly"
          />
        </el-form-item>
      </el-form>
      <div class="text-right" v-if="formType !== 'show'">
        <el-button icon="el-icon-check" type="primary" @click="onSave">저장</el-button>
        <el-button icon="el-icon-close" type="info" @click="onCancel">취소</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import MultiResourceForm from './MultiResourceForm.vue';
import SFInputNumber from '@/components/form/SFInputNumber'
import SFRadioSelect from '@/components/form/SFRadioSelect'
import SFResourceSelect from '@/components/form/SFResourceSelect'
import SFInput from '@/components/form/SFInput'
import SFDateTimeInput from '@/components/form/SFDateTimeInput'
import SFMatchingMember from '@/components/form/SFMatchingMember'
import SFInputPassword from '@/components/form/SFInputPassword'
import SFMultiSelect from '@/components/form/SFMultiSelect'

export default {
  name: 'ResourceForm',
  components: {
    SFMultiSelect,
    SFInputPassword,
    SFMatchingMember,
    SFDateTimeInput,
    SFInput,
    SFResourceSelect,
    SFRadioSelect,
    SFInputNumber,
    MultiResourceForm
  },
  computed: {
    readOnly() {
      return this.formType == 'show'
    },
    formSchema() {
      return this.readOnly ? this.schema.show : this.schema.edit
    },
    id() {
      return this.$route.params.id
    },
    formType() {
      return this.$route.meta && this.$route.meta.formType
    },
    resource() {
      return this.$route.meta && this.$route.meta.resource
    }
  },
  props: ['schema'],
  data() {
    return {
      form: {},
      error: {}
    }
  },
  watch: {
    $route() {
      if (this.formType == 'new') {
        this.form = {}
      }
      if (this.id) {
        this.loadData()
      }
    }
  },
  methods: {
    onEdit() {
      this.$router.push({ name: this.$route.meta.pathName + '.edit' })
    },
    onDownloadExcel(){
      this.$apiServer
          .get(`api/v1/${this.resource}/${this.id}/excel.json`,{responseType: "blob"})
          .then(response => {
            const fileObjectUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = fileObjectUrl;
            link.style.display = "none";
            link.download = this.extractDownloadFilename(response);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(fileObjectUrl);
          })
    },
    extractDownloadFilename(response) {
      const disposition = response.headers["content-disposition"];
      const fileName = decodeURI(
        disposition
          .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
          .replace(/['"]/g, "")
      );
      return fileName;
    },
    onDelete() {
      this.$confirm(this.$t('선택한 항목을 정말 삭제 하시겠습니까?'), '주의', {
        confirmButtonText: this.$t('삭제'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$apiServer
          .delete(`api/v1/${this.resource}/${this.id}.json`)
          .then(() => {
            this.$eventBus.$emit('resourceUpdated')
            this.$router.replace({ name: this.$route.meta.pathName })
            this.$message({
              type: 'success',
              message: this.$t('대상 항목이 삭제되었습니다.')
            })
          })
      })
    },
    onSave() {
      this.error = {}
      const fields = Object.keys(this.formSchema)
      let data = {}
      fields.forEach(k => (data[k] = this.form[k]))
      const action =
        this.formType == 'edit' && this.id
          ? this.$apiServer.put(`api/v1/${this.resource}/${this.id}.json`, {
              ...data,
              ...this.schema.default
            })
          : this.$apiServer.post(`api/v1/${this.resource}.json`, {
              ...data,
              ...this.schema.default
            })

      action
        .then(response => {
          const data = response.data
          this.$router.replace({
            name: this.$route.meta.pathName + '.show',
            params: { id: data.id }
          })
          this.$eventBus.$emit('resourceUpdated', data)
        })
        .catch(error => {
          const data = error.response.data
          Object.keys(data).forEach(k => (this.error[k] = this.$t(data[k][0])))
          this.$forceUpdate()
        })
    },
    onCancel() {
      this.$router.back()
    },
    loadData() {
      if (this.id) {
        this.$emit('loadData')
        this.$apiServer
          .get(`api/v1/${this.resource}/${this.id}.json`)
          .then(response => {
            this.form = response.data
          })
          .catch(() => {
            this.$router.replace({ name: this.$route.meta.pathName })
          })
      }
    },
    formatToComponent(key, field) {
      switch (field.type) {
        case 'integer':
          return 'SFInputNumber'
        case 'float':
          return 'SFInputNumber'
        case 'string':
          return 'SFInput'
        case 'radio':
          return 'SFRadioSelect'
        case 'resource':
          return 'SFResourceSelect'
        case 'matching_member':
          return 'SFMatchingMember'
        case 'multi_select':
          return 'SFMultiSelect'
        case 'datetime':
          return 'SFDateTimeInput'
        case 'password':
          return 'SFInputPassword'
        default:
          return 'MpFormItemInput'
      }
    },
    onBulkRegister() {
      this.$emit('bulkRegister')
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

$max-width: 800px;

.detail {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px 50px 60px;
}

.title-wrapper {
  margin-top: 0px;
  margin-bottom: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    flex-grow: 1;
  }

  .bulk-register-button {
    margin-left: auto;
  }

  .actions {
    margin-bottom: 30px;
    ::v-deep .el-button {
      height: 37px;
      padding: 0 10px;
      border-radius: 5px;

      &.default {
        color: $text-grey;
      }

      > span {
        display: inline-flex;
        align-items: center;
      }
      .icon {
        font-size: 20px;
        margin-left: 12px;
      }
    }
  }
  ::v-deep {
    h2 {
      font-size: 25px;
      line-height: 37px;
      font-weight: normal;
    }

    .el-form {
      max-width: $max-width;
      border: 1px solid $--border-color-base;
      padding: 20px;
      border-radius: 4px;

      .el-input {
      }
    }
  }
}

.form {
  max-width: $max-width;
  border-radius: 4px;

  /deep/ {
    .el-input,
    .el-select {
      width: 100%;
      max-width: 300px;
    }
  }

  .el-form.show {
    .el-form-item {
    }
  }
}
</style>