/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'

//apps
import DashBoard from '@/views/pages/DashBoard.vue'
import ClassPage from '@/views/pages/ClassPage.vue'

import store from '@/store'
import { LAYOUT_TYPE } from '@/constants'
import MemberPage from '@/views/pages/MemberPage'
import TrainerPage from '@/views/pages/TrainerPage'
import HerafitPage from '@/views/pages/HerafitPage'
import LoginPage from '@/views/pages/LoginPage'
import SignUpPage from '@/views/pages/SignUpPage'
import FindPwdPage from '@/views/pages/FindPwdPage'
import SetupPage from '@/views/pages/SetupPage'
import ReceiverPage from '@/views/pages/setup/ReceiverPage'
import UserInfo from '@/views/pages/setup/UserInfo'
import Usage from '@/views/pages/setup/Usage'
import Logo from '@/views/pages/setup/Logo'
import AppInfo from '@/views/pages/setup/AppInfo'
import DashboardRunning from '@/views/pages/DashboardRunning'
import WorkoutResultPage from '@/views/pages/WorkoutResultPage'
import HistoryPage from '@/views/pages/HistoryPage'

Vue.use(Router)

function createResourceRoute(pageComponent, pathName, resource) {
  return [
    {
      path: '',
      name: pathName,
      component: pageComponent,
      meta: {
        pathName: pathName,
        resource: resource
      }
    },
    {
      path: 'new',
      name: pathName + '.new',
      component: pageComponent,
      meta: {
        formType: 'new',
        pathName: pathName,
        resource: resource
      }
    },
    {
      path: ':id',
      name: pathName + '.show',
      component: pageComponent,
      meta: {
        formType: 'show',
        pathName: pathName,
        resource: resource
      }
    },
    {
      path: ':id/edit',
      name: pathName + '.edit',
      component: pageComponent,
      meta: {
        formType: 'edit',
        pathName: pathName,
        resource: resource
      }
    }
  ]
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/dashboard/web',
      name: 'dashboard.web',
      component: DashboardRunning,
      props: () => ({ type: 'web' }),
      meta: {
        layout: LAYOUT_TYPE.fullScreen,
        auth: false
      }
    },
    {
      path: '/dashboard/app',
      name: 'dashboard.app',
      component: DashboardRunning,
      props: () => ({ type: 'app' }),
      meta: {
        layout: LAYOUT_TYPE.fullScreen
      }
    },
    {
      path: '/dashboard/app/:id',
      name: 'dashboard.app.id',
      component: DashboardRunning,
      props: () => ({ type: 'app' }),
      meta: {
        layout: LAYOUT_TYPE.fullScreen
      }
    },
    {
      path: '/',
      name: 'home',
      redirect: '/dashboard',
      meta: {
        layout: LAYOUT_TYPE.default
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
      meta: {
        layout: LAYOUT_TYPE.fullScreen,
        auth: false
      }
    },
    {
      path: '/signup',
      name: 'Signup',
      component: SignUpPage,
      meta: {
        layout: LAYOUT_TYPE.withImageHead,
        auth: false
      }
    },
    {
      path: '/findpwd',
      name: 'FindPwd',
      component: FindPwdPage,
      meta: {
        layout: LAYOUT_TYPE.withImageHead,
        auth: false
      }
    },
    {
      path: '/herafit',
      component: HerafitPage,
      meta: {
        layout: LAYOUT_TYPE.default
      },
      children: createResourceRoute(HerafitPage, 'herafit', 'device_infos')
    },
    {
      path: '/class',
      component: ClassPage,
      meta: {
        layout: LAYOUT_TYPE.default
      },
      children: [
        ...createResourceRoute(ClassPage, 'class', 'classes'),
        {
          path: ':id/history',
          name: 'class.history',
          component: ClassPage,
          meta: {
            formType: 'history',
            pathName: 'class',
            resource: 'classes'
          }
        },
        {
          path: ':id/history/:wce_id',
          name: 'class.history.show',
          component: ClassPage,
          meta: {
            formType: 'history',
            pathName: 'class',
            resource: 'classes'
          }
        }
      ]
    },
    {
      path: '/member',
      component: MemberPage,
      meta: {
        layout: LAYOUT_TYPE.default
      },
      children: [
        ...createResourceRoute(MemberPage, 'member', 'members'),
        {
          path: ':id/history',
          name: 'member.history',
          component: MemberPage,
          meta: {
            formType: 'history',
            pathName: 'member',
            resource: 'members'
          }
        },
        {
          path: ':id/history/:wce_id',
          name: 'member.history.show',
          component: MemberPage,
          meta: {
            formType: 'history',
            pathName: 'member',
            resource: 'members'
          }
        }
      ]
    },
    {
      path: '/trainer',
      component: TrainerPage,
      meta: {
        layout: LAYOUT_TYPE.default
      },
      children: createResourceRoute(TrainerPage, 'trainer', 'users')
    },
    {
      path: '/setup',
      name: 'setup',
      component: SetupPage,
      redirect: { name: 'setup.user_info' },
      meta: {
        layout: LAYOUT_TYPE.default
      },
      children: [
        {
          path: 'user_info',
          name: 'setup.user_info',
          component: UserInfo
        },
        {
          path: 'receiver',
          component: ReceiverPage,
          children: createResourceRoute(
            ReceiverPage,
            'setup.receiver',
            'receiver_infos'
          )
        },
        {
          path: 'usage',
          name: 'setup.usage',
          component: Usage
        },
        {
          path: 'logo',
          name: 'setup.logo',
          component: Logo
        },
        {
          path: 'app_info',
          name: 'setup.app_info',
          component: AppInfo
        }
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashBoard,
      meta: {
        layout: LAYOUT_TYPE.default
      }
    },
    {
      path: '/history',
      name: 'history',
      component: HistoryPage,
      meta: {
        layout: LAYOUT_TYPE.default
      }
    },
    {
      path: '/class/:class_id/result',
      name: 'workout_result',
      component: WorkoutResultPage,
      meta: {
        layout: LAYOUT_TYPE.default
      }
    },
    {
      path: '/class/:class_id/result/:id',
      name: 'workout_result.detail',
      component: WorkoutResultPage,
      meta: {
        layout: LAYOUT_TYPE.default
      }
    }
  ]
})

//insert here login logic
const auth = {
  loggedIn() {
    return store.getters.isLoggedIn
  },
  logout() {
    store.commit('setLogout')
    window.sessionStorage.removeItem('auth_token')
  }
}

const vm = new Vue()

router.beforeEach((to, from, next) => {
  let authrequired = true
  if (to && to.meta && to.meta.auth == false) authrequired = false

  //console.log('authrequired', authrequired, to.name)

  if (authrequired && !auth.loggedIn()) {
    vm.$apiServer
      .get('auth/users/me.json', { maxRedirects: 1 })
      .then(response => {
        const data = response.data
        store.commit('setLogin', data)
        if (to.name === 'Login') {
          next({ path: '/' })
          return false
        } else {
          next()
        }
      })
      .catch(error => {
        if (to.name !== 'login') {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
          return false
        }
        next()
      })
  } else {
    if (auth.loggedIn() && to.name === 'login') {
      next({ path: '/' })
      return false
    } else {
      next()
    }
  }

  if (to) {
    if (to.meta && to.meta.layout) {
      store.commit('setLayout', to.meta.layout)
    } else {
      store.commit('setLayout', LAYOUT_TYPE.default)
    }
  }
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit('setSplashScreen', false)
  }, 10)
})

export default router
