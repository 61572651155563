<template>
  <div>
    <div class="content-step-3">
      <div class="title">{{ $t('FIND_PASSWORD') }}(ID)</div>
      <el-form class="form" label-width="200px" label-position="left">
        <el-form-item
            :label="`${$t('EMAIL')}(ID)`"
            :error="error && error.address && $t('REGIST_LOCATION_INPUT')"
        >
          <el-input
              v-model="data.address"
              :placeholder="$t('REGIST_LOCATION_INPUT')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('CLUB_NAME')"
          :error="error && error.name && $t('REGIST_CLUB_NAME_INPUT')"
        >
          <el-input
            v-model="data.name"
            :placeholder="$t('REGIST_CLUB_NAME_INPUT')"
          />
        </el-form-item>
        <el-form-item
            :label="$t('CLUB_PHONE_NUM')"
            :error="error && error.phone && $t('PLZ_INPUT_CLUB_TELNO')"
        >
          <el-input
              v-model="data.phone"
              :placeholder="$t('ONLY_INPUT_NUMBER')"
          />
        </el-form-item>
      </el-form>

      <div class="actions">
        <el-button type="info" @click="cancel">
          {{ $t('CANCEL') }}
        </el-button>
        <el-button type="primary" @click="next">
          {{ $t('CONFIRM') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@/lib/utils'

export default {
  name: 'Step3',
  data() {
    return {
      data: {},
      error: {}
    }
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    next() {
      this.error = {}
      if (isEmpty(this.data.name)) {
        this.error.name = 'required'
      }
      if (isEmpty(this.data.address)) {
        this.error.address = 'required'
      }
      if (isEmpty(this.data.phone)) {
        this.error.phone = 'required'
      }
      if (this.error && Object.keys(this.error).length > 0) {
        this.$forceUpdate()
        return
      }

      this.$emit('next', this.data)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';
.content-step-3 {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  .form {
    ::v-deep {
      label {
        font-size: 18px;
      }
    }
    border-radius: 10px;
    border: solid 1px $border-grey;
    padding: 30px;
  }
}

.title {
  font-size: 16px;
  color: $dark-black;
  margin-bottom: 10px;
}
p.error {
  font-size: 12px;
  color: $--color-danger;
}

.actions {
  @include actions;
}
</style>
