<template>
  <div class="history-content" v-if="formType === 'history'">
    <div class="report-actions">
      <el-button @click="onDownloadExcel(historyDetail)" class="default">
        <span>다운로드</span><span class="icon el-icon-download" />
      </el-button>
      <el-button @click="onDelete(historyDetail)" class="default">
        <span>삭제</span><span class="icon el-icon-delete" />
      </el-button>
    </div>
    <div ref="reportContainer">
      <ClassReport v-loading="!historyDetail" :detail="historyDetail" />
    </div>
  </div>
  <ResourceForm v-else :schema="schema" :id="id" @loadData="onFormLoad">
    <template #header="scope">
      <h2>
        {{ scope.form.name }}
      </h2>
    </template>
    <h2 slot="empty">{{ $t('클래스를 선택해주세요.') }}</h2>
    <h2 slot="new">{{ $t('새 클래스 추가') }}</h2>
    <template #actions>
      <el-button type="primary" @click="onStart"
        ><span>운동 시작</span><span class="icon el-icon-video-play" />
      </el-button>
    </template>
    <template #show="scope">
      <div class="member-show">
        <h3>{{ $t('클래스정보') }}</h3>
        <div class="member-info">
          <div class="item">
            <span class="label">{{ $t('TARGET_HEART_BEAT') }}</span>
            <span class="value">{{
              targetHeartRate(scope.form.goal_heart_rate_type)
            }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('MATCHING_MEMBER') }}</span>
            <span class="value">{{
              scope.form.member_devices?.length + ` ${$t('명')}`
            }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('RUNNING_TIME') }}</span>
            <span class="value">{{
              scope.form.duration + ` ${$t('MIN')}`
            }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('REGIST_DATE') }}</span>
            <span class="value">{{
              toStartedTime(scope.form.created_at)
            }}</span>
          </div>
        </div>
        <div class="header-container">
          <h3>{{ $t('운동이력') }}</h3>
          <div class="actions">
            <el-button @click="deleteSelectedItems" class="default">
              <span>선택항목 삭제</span><span class="icon el-icon-delete" />
            </el-button>
          </div>
        </div>
        <div class="history">
          <div v-for="(h, i) in historyFiltered" :key="i" class="history-item-wrapper">
            <div class="history-item-container">
              <input type="checkbox" v-model="selectedItems" :value="h.id" class="custom-checkbox" />
              <div
                class="history-item"
                @click="onClickHistoryItem(h.id)"
              >
                <div>
                  <div class="value">{{ toStartedTime(h.started_at) }}</div>
                  <div class="unit">{{ $t('운동일시') }}</div>
                </div>
                <div>
                  <div class="value">{{ h.active_member + '명' }}</div>
                  <div class="unit">{{ $t('참여인원') }}</div>
                </div>
                <div>
                  <div class="value">{{ getRunningTime(h) }}</div>
                  <div class="unit">{{ $t('운동시간') }}</div>
                </div>
                <div>
                  <div class="value">{{ Math.round(h.avg_hr) }}</div>
                  <div class="unit">{{ $t('평균심박(BPM)') }}</div>
                </div>
                <div>
                  <div class="value">{{ Math.round(h.avg_calories / 3) }}</div>
                  <div class="unit">{{ $t('평균 칼로리(Kcal)') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ResourceForm>
</template>

<script>
import ResourceForm from '@/components/ResourceForm'
import moment from 'moment'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import ClassReport from '@/components/ClassReport'

export default {
  name: 'ClassForm',
  components: { ClassReport, ResourceForm },
  props: ['classOptions', 'workoutOptions', 'trainerOptions'],
  mixins: [MixinFormatUtils],
  computed: {
    id() {
      return this.$route.params.id
    },
    formType() {
      return this.$route.meta && this.$route.meta.formType
    },
    wceId() {
      return this.$route.params.wce_id
    },
    historyFiltered() {
      return this.history.filter(h => h.avg_hr)
    }
  },
  watch: {
    wceId() {
      if (this.wceId) {
        this.loadHistoryDetail()
      }
    }
  },
  data() {
    const schema = {
      name: {
        label: this.$t('CLASS_NAME'),
        type: 'string'
      },
      goal_heart_rate_type: {
        label: `${this.$t('TARGET_HEART_BEAT')} (%)`,
        type: 'resource',
        option: [
          { id: 1, name: `0~59% ${this.$t('WARMING_UP')}` },
          { id: 2, name: `60~69% ${this.$t('FAT_BURNING')}` },
          { id: 3, name: `70~79% ${this.$t('IMPROVE_ENDURANCE')}` },
          { id: 4, name: `80~89% ${this.$t('MAX_EXERCISE_EFFECT')}` },
          { id: 5, name: `90~100% ${this.$t('MAX_STRANGTH')}` },
          { id: 9, name: this.$t('MANUAL_INPUT') }
        ]
      },
      member_devices: {
        label: `${this.$t('MATCHING_MEMBER')} (${this.$t('LIMIT_30')})`,
        type: 'matching_member'
      },
      duration: {
        label: `${this.$t('RUNNING_TIME')} (${this.$t('MIN')})`,
        type: 'integer'
      },
      trainers: {
        label: this.$t('MATCHING_TRAINNER'),
        type: 'multi_select',
        option: {
          resource: 'users',
          displayText: v => `${v.name}(${v.email})`
        }
      }
    }
    const show = Object.assign({}, schema, {
      created_at: {
        label: this.$t('REGIST_DATE'),
        type: 'datetime'
      }
    })
    return {
      form: {},
      historyDetail: null,
      history: [],
      selectedItems: [], // Array to keep track of selected items
      schema: {
        edit: schema,
        show: show,
        default: {
          class_type: 1,
          workout_type: 1
        }
      }
    }
  },
  methods: {
    onStart() {
      // eslint-disable-next-line no-unused-vars
      this.$apiServer
        .post(`api/v1/classes/${this.id}/start.json`)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.summary = response.data
          this.$router.push({ name: 'dashboard.app' })
        })
        .catch(error => {
          if (error.response.status == 403) {
            const data = error.response.data
            const workouts = data.map(
              i =>
                `<div><b>${i.name} (${moment(i.started_at).format(
                  'YYYY-MM-DD HH:mm'
                )})</b></div>`
            )
            this.$confirm(
              `${this.$t(
                '실행중인 클래스가 있습니다. 실행중인 클래스를 종료 하시겠습니까?'
              )}${workouts.join()}`,
              '',
              {
                confirmButtonText: this.$t('종료'),
                cancelButtonText: this.$t('취소'),
                dangerouslyUseHTMLString: true,
                showClose: false,
                type: 'info',
                center: true
              }
            ).then(() => {
              this.$apiServer
                .post(`api/v1/classes/${this.id}/stop_all.json`)
                .then(() => {
                  this.$message({
                    type: 'success',
                    message: this.$t('종료되었습니다.')
                  })
                })
            })
          }
        })
    },
    targetHeartRate(type) {
      const target = this.schema.show.goal_heart_rate_type.option.find(
        o => o.id == type
      )
      return target && target.name
    },
    showResult() {
      this.$router.push({
        name: 'workout_result',
        params: { class_id: this.id }
      })
    },
    getRunningTime(item) {
      return this.toRunningTime(
        (Date.parse(item.stopped_at) - Date.parse(item.started_at)) / 1000 -
          item.paused_time
      )
    },
    onFormLoad() {
      this.history = []
      this.$apiServer
        .get(`api/v1/classes/${this.id}/history.json`)
        .then(response => {
          this.history = response.data.list
        })
        .catch(() => {
          this.$router.replace({ name: this.$route.meta.pathName })
        })
    },
    onClickHistoryItem(wceId) {
      this.$router.push({
        name: this.$route.meta.pathName + '.history.show',
        params: { wce_id: wceId, id: this.id }
      })
    },
    loadHistoryDetail() {
      this.historyDetail = null
      this.$apiServer
        .get(`api/v1/classes/${this.id}/result/${this.wceId}.json`)
        .then(response => {
          this.historyDetail = response.data
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    onDownloadExcel(){
      this.$apiServer
          .get(`api/v1/classes/${this.id}/result/${this.wceId}/excel.json`,{responseType: "blob"})
          .then(response => {
            const fileObjectUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = fileObjectUrl;
            link.style.display = "none";
            link.download = this.extractDownloadFilename(response);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(fileObjectUrl);
          })
    },
    extractDownloadFilename(response) {
      const disposition = response.headers["content-disposition"];
      const fileName = decodeURI(
        disposition
          .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
          .replace(/['"]/g, "")
      );
      return fileName;
    },
    async deleteSelectedItems() {
      if (this.selectedItems.length === 0)
      {
        this.$message({
          type: 'warning',
          message: this.$t('삭제할 항목을 선택해주세요.')
        });
        return;
      }
      this.$confirm(this.$t('선택한 운동이력을 정말 삭제 하시겠습니까?') + ' 삭제할 항목 개수: ' + this.selectedItems.length, '운동이력 삭제', {
        confirmButtonText: this.$t('삭제'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true
      }).then(() => {
        const deletePromises = this.selectedItems.map(itemId => {
          return this.$apiServer.delete(`api/v1/history/${itemId}/class/${this.id}/delm.json`)
            .then(response => {
              console.log(`Deleted item with wce id: ${itemId}`, response);
              // Remove the item from history on successful deletion
              this.history = this.history.filter(h => h.id !== itemId);
            })
            .catch(error => {
              console.error(`Failed to delete item with id: ${itemId}`, error);
              // Optionally handle the error, e.g., show a notification to the user
            });
        });

        Promise.all(deletePromises).then(() => {
          // Clear the selection after all deletions are processed
          this.selectedItems = [];
        });
      });
    },
    async onDelete(historyDetail) {
      this.$confirm(this.$t('현재 운동이력을 정말 삭제 하시겠습니까?'), '운동이력 삭제', {
        confirmButtonText: this.$t('삭제'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true
      }).then(() => {
        const itemId = historyDetail.id;
        this.$apiServer.delete(`api/v1/history/${itemId}/class/${this.id}/delm.json`)
            .then(response => {
              console.log(`Deleted item with wce id: ${itemId}`, response);
              // Remove the item from history on successful deletion
              this.history = this.history.filter(h => h.id !== itemId);
              this.$router.back()
              //window.location.reload();
              this.$forceUpdate();
            })
            .catch(error => {
              console.error(`Failed to delete item with id: ${itemId}`, error);
              // Optionally handle the error, e.g., show a notification to the user
            });
      });
    }
  },
  mounted() {
    if (this.formType === 'history' && this.wceId) {
      this.loadHistoryDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

.header-container {
  display: flex;
  align-items: flex-start; // Align items to the top
}

.header-container h3 {
  margin-right: 20px; // Adjust the spacing as needed
}

.actions {
  display: flex;
  align-items: flex-start; // Align button to the top
}

.el-button.default {
  margin-top: 0; // Remove any margin that might affect alignment
}

.history-item-container {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  width: 20px; // Increase the size of the checkbox
  height: 20px; // Increase the size of the checkbox
  margin-right: 10px; // Add space between the checkbox and the history-item div
}

.history-item {
  flex-grow: 1;
  cursor: pointer;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.member-info {
  padding: 20px 12px;
  margin-left: -10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: solid 1px $light-grey;
  width: calc(100% + 10px);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 50%;
    display: flex;
    font-size: 16px;

    > .label {
      width: 100px;
      color: $text-grey;
    }

    > .value {
      flex-grow: 1;
    }

    margin-bottom: 18px;
  }
}

.history-content {
  flex: 1;
}

@include history-list;
.history-item {
  height: 80px;
  > div:first-child {
    max-width: initial;
    padding-right: 0;
  }

  > div {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .value {
      font-size: 18px;
      margin-bottom: 5px;
    }
    .unit {
      font-size: 14px;
    }
  }
}

.report-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .el-button {
    margin-left: 10px;
  }
}
</style>
