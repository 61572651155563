<template>
  <div class="full-page-wrapper skin-blue">
    <!-- Main Header -->
    <div class="sidebar">
      <div class="logo">
        
        <img
          v-if="user?.shop && user?.shop?.logo"
          :src="user?.shop?.logo"
        />
        <img
          v-else
         src="~@/assets/img/logo.png"
        />
      </div>
      <div class="menu">
        <div class="scroll">
          <el-menu router :default-active="defaultMenuIndex" class="el-menu">
            <el-menu-item index="1" :route="{ name: 'home' }">
              <i class="icon icon-home"></i>
              <span>{{ $t('MENU_HOME') }}</span>
            </el-menu-item>
            <el-menu-item index="2" :route="{ name: 'history' }">
              <i class="icon icon-history"></i>
              <span>{{ $t('MENU_HISTORY') }}</span>
            </el-menu-item>
            <el-menu-item index="3" :route="{ name: 'member' }">
              <i class="icon icon-member"></i>
              <span>{{ $t('MENU_MEMBER') }}</span>
            </el-menu-item>
            <el-menu-item index="4" :route="{ name: 'class' }">
              <i class="icon icon-class"></i>
              <span>{{ $t('MENU_CLASS') }}</span>
            </el-menu-item>
            <el-menu-item index="5" :route="{ name: 'herafit' }">
              <i class="icon icon-herafit"></i>
              <span>{{ $t('MENU_HERAFIT') }}</span>
            </el-menu-item>
            <el-submenu index="6">
              <template slot="title">
                <i class="icon icon-settings"></i>
                <span>{{ $t('MENU_SETUP') }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  index="6-1"
                  :route="{ name: 'setup.user_info' }"
                  >{{ $t('MENU_SETUP_USER') }}</el-menu-item
                >
                <el-menu-item index="6-2" :route="{ name: 'setup.receiver' }">{{
                  $t('MENU_SETUP_RECEIVER')
                }}</el-menu-item>
                <el-menu-item index="6-3" :route="{ name: 'setup.usage' }">{{
                  $t('MENU_SETUP_USAGE')
                }}</el-menu-item>
                <el-menu-item index="6-4" :route="{ name: 'setup.logo' }">{{
                  $t('MENU_SETUP_LOGO')
                }}</el-menu-item>
                <el-menu-item index="6-5" :route="{ name: 'setup.app_info' }">{{
                  $t('MENU_SETUP_APP')
                }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="logout">
              <i class="icon icon-logout"></i>
              <span>{{ $t('LOGOUT') }}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <el-drawer :visible="false" direction="ltr" size="290px">
      <aside class="main-sidebar">
        <section class="sidebar">
          <!-- Sidebar Menu -->
          <ul class="sidebar-menu" data-widget="tree">
            <li class="header">
              <h4>{{ user?.shop.name }}</h4>
            </li>
            <li class="header">
              <label class="label bg-orange">
                {{ authCode == 1 ? $t('TRAINNER') : $t('ADMIN') }}
              </label>
              <h3>manager{{ user?.name }}</h3>
            </li>
            <hr style="border: 1px solid #4868c3" />
            <!-- Optionally, you can add icons to the links -->
            <li :class="{ active: isActive('dashboard') }">
              <router-link to="/dashboard">
                <span class="mgl5 ftMenu">
                  {{ $t('MENU_HOME') }}</span
                ></router-link
              >
            </li>

            <li :class="{ active: isActive('herafit') }">
              <router-link to="/herafit/">
                <span class="mgl5 ftMenu">
                  {{ $t('MENU_HERAFIT') }}</span
                ></router-link
              >
            </li>
            <li
              :class="{ active: isActive('trainer') }"
              v-if="authCode == 2"
            >
              <router-link to="/trainer">
                <span class="mgl5 ftMenu">
                  {{ $t('MENU_TRANINER') }}</span
                ></router-link
              >
            </li>
            <li :class="{ active: isActive('member') }">
              <router-link to="/member">
                <span class="mgl5 ftMenu"> </span
              ></router-link>
            </li>
            <li :class="{ active: isActive('class') }">
              <router-link to="/class/">
                <span class="mgl5 ftMenu"> </span
              ></router-link>
            </li>
            <li
              :class="{ active: isActive('setup') }"
              v-if="user == 2"
            >
              <router-link to="/setup">
                <span class="mgl5 ftMenu"> </span
              ></router-link>
            </li>
          </ul>
          <!-- /.sidebar-menu -->
        </section>
        <!-- /.sidebar -->
      </aside>
    </el-drawer>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper height100p wd100p" id="main_contents_wrapper">
      <div class="content">
        <slot></slot>
      </div>
    </div>
    <!-- /.content-wrapper -->
    <HerafitBuy :dialogvisible.sync="visibleBuy" />
  </div>
</template>

<script>
import HerafitBuy from '@/components/modal/HerafitBuy'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'DefaultLayout',
  components: { HerafitBuy },
  computed: {
    ...mapGetters(['user']),
    
    defaultMenuIndex() {
      if (!this.$route.name) return '1'
      else if (this.$route.name.startsWith('home')) return '1'
      else if (this.$route.name.startsWith('history')) return '2'
      else if (this.$route.name.startsWith('member')) return '3'
      else if (
        this.$route.name.startsWith('class') ||
        this.$route.path.startsWith('/class')
      )
        return '4'
      else if (this.$route.name.startsWith('herafit')) return '5'
      else if (this.$route.name.startsWith('setup.user_info')) return '6-1'
      else if (this.$route.name.startsWith('setup.receiver')) return '6-2'
      else if (this.$route.name.startsWith('setup.usage')) return '6-3'
      else if (this.$route.name.startsWith('setup.logo')) return '6-4'
      else if (this.$route.name.startsWith('setup.app_info')) return '6-5'
      else return '1'
    },
    title() {
      if (this.isActive('dashboard')) {
        return ''
      } else if (this.isActive('herafit')) {
        return this.$t('MENU_HERAFIT')
      } else if (this.isActive('trainer')) {
        return this.$t('MENU_TRANINER')
      } else if (this.isActive('member')) {
        return this.$t('MENU_MEMBER')
      } else if (this.isActive('class')) {
        return this.$t('MENU_CLASS')
      } else if (this.isActive('setup')) {
        return this.$t('MENU_SETUP')
      }
      return this.$route.name
    }
  },
  data() {
    return {
      gear: true,
      cart: true,
      authCode: 2,
      
      
      showSidebar: false,
      visibleBuy: false
    }
  },
  watch: {
    $route() {
      this.showSidebar = false
    }
  },
  methods: {
    ...mapMutations(['setLogout']),
    logout() {
      this.$confirm(this.$t('로그아웃 하시겠습니까?'), '', {
        confirmButtonText: this.$t('확인'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$apiServer.post('auth/token/logout').then(() => {
          window.sessionStorage.removeItem('auth_token')
          this.setLogout()
          this.$router.push('/')
        })
      })
    },
    isActive(path) {
      return this.$route.fullPath.includes(path)
    },
    showMenu(show) {
      this.showSidebar = show
    },
    
  },
  mounted() {
    this.showSidebar = false
    
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.full-page-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  > .content-wrapper {
    flex-grow: 1;
    display: flex;
    padding: 20px 30px 20px 0;
    > .content {
      display: flex;
      flex: 1;
      border-radius: 20px;
      background-color: #f4f4f4;
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  > .menu {
    flex: 1;
    position: relative;
    .scroll {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      .el-menu {
        border-right: none;
        color: #00ffff;
      }
    }
  }
  .logo {
    box-sizing: content-box;
    padding: 70px 0;
    text-align: center;
    img {
      display: inline-block;
      width: 175px;
    }
  }
  ::v-deep .el-submenu__title,
  .el-menu-item {
    font-size: 16px;
    color: #555555;
    &.is-active {
      color: $--color-primary;
    }
  }
  .el-submenu {
    &.is-active {
      ::v-deep .el-submenu__title {
        color: $--color-primary;
      }
    }
    .el-menu-item {
      padding-left: 60px !important;
    }
  }
  width: 300px;
  min-width: 300px;
  flex-basis: 300px;
  flex-shrink: 0;
  .icon {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 12px;
    margin-top: -5px;
  }
  .icon-home {
    background-image: url('~@/assets/icons/icon-menu-home.png');
  }
  .icon-history {
    background-image: url('~@/assets/icons/icon-menu-history.png');
  }
  .icon-member {
    background-image: url('~@/assets/icons/icon-menu-member.png');
  }
  .icon-class {
    background-image: url('~@/assets/icons/icon-menu-class.png');
  }
  .icon-herafit {
    background-image: url('~@/assets/icons/icon-menu-herafit.png');
  }
  .icon-settings {
    background-image: url('~@/assets/icons/icon-menu-settings.png');
  }
  .icon-logout {
    background-image: url('~@/assets/icons/icon-menu-logout.png');
  }
  .is-active {
    .icon-home {
      background-image: url('~@/assets/icons/icon-menu-home-active.png');
    }
    .icon-history {
      background-image: url('~@/assets/icons/icon-menu-history-active.png');
    }
    .icon-member {
      background-image: url('~@/assets/icons/icon-menu-member-active.png');
    }
    .icon-class {
      background-image: url('~@/assets/icons/icon-menu-class-active.png');
    }
    .icon-herafit {
      background-image: url('~@/assets/icons/icon-menu-herafit-active.png');
    }
    .icon-settings {
      background-image: url('~@/assets/icons/icon-menu-settings-active.png');
    }
    .icon-logout {
      background-image: url('~@/assets/icons/icon-menu-logout-active.png');
    }
  }
}

.navbar {
  padding: 0;
}

.content-wrapper {
  .content {
    > .el-container {
      overflow-y: auto;
    }
  }
}
</style>
