<template>
  <div class="dashboard font-white">
    <div v-if="type == 'web' && ready" class="logo-page">
      <img class="logo" v-if="user.shop.logo" :src="user.shop.logo" width="40" />
      <img v-else
        width="35"
        @click="$router.push('/')"
        class="logo"
        src="~@/assets/img/logo.png"
      />
    </div>
    <div v-else class="content-wrapper">
      <div class="main-header" :style="{backgroundColor: user.shop.theme_color} ">
        <DashBoardTab v-model="tabMode" />
        <img class="logo" v-if="user.shop.logo" :src="user.shop.logo" width="40" />
        <img v-else
          width="35"
          @click="$router.push('/')"
          class="logo"
          src="~@/assets/img/logo-inverse.png"
        />
        <div v-if="type == 'app'" class="actions">
          <button
            v-if="workout_class.state != 1"
            type="button"
            class="btn btn-play"
            @click="start"
          >
            {{ $t('START') }}
          </button>
          <button v-else type="button" class="btn btn-pause" @click="pause">
            {{ $t('PAUSE') }}
          </button>
          <button
            v-if="workout_class.state == 0"
            type="button"
            class="btn btn-cancel"
            @click="cancel"
          >
            {{ $t('CANCEL') }}
          </button>
          <button v-else type="button" class="btn btn-stop" @click="stop">
            {{ $t('FINISH') }}
          </button>
          <!--          <button-->
          <!--            type="button"-->
          <!--            class="btn btn-blank dpn"-->
          <!--            id="excercise_go_result"-->
          <!--          >-->
          <!--            {{ $t('RESULT_CHECK') }}-->
          <!--          </button>-->
        </div>
      </div>
      <div class="user-board">
        <el-row :gutter="20">
          <el-col :span="4" class="" v-for="(u, i) in users" :key="i">
            <UserCard :user="u" :shop="user.shop" :type="tabMode" />
          </el-col>
        </el-row>
      </div>
      <div class="title-bar">
        <span class="class-name">{{ workout_class.name }}</span>
        <div class="time">
          <span>{{ format_seconds(elapsedTime * 1000) }}</span>
          <span v-if="false"
            >({{ format_seconds(workout_class.duration * 60 * 1000) }})</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from '@/components/UserCard'
import moment from 'moment'
import DashBoardTab from '@/components/DashBoardTab'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardRunning',
  components: { DashBoardTab, UserCard },
  computed: {
    ...mapGetters(['user']),
    wce_id() {
      return this.$route.params.id
    }
  },
  data() {
    return {
      ready: true,
      reloadInterval: null,
      shop: {},
      users: [],
      workout_class: {},
      elapsedTime: 0,
      tabMode: 1,
      apiTickCounter: 0
    }
  },
  props: {
    type: {
      type: String,
      default: 'app'
    }
  },
  methods: {
    format_seconds(seconds) {
      if (this.workout_class.duration)
        return moment.utc(seconds).format('HH:mm:ss')
      else return '00:00:00'
    },
    loadUsers() {
      this.apiTickCounter += 1
      if (this.apiTickCounter % 2 == 0) {
        if (this.workout_class.state == 1) {
          this.elapsedTime += 1
        }
        return;
      }
      let action = null
      if (this.type == 'web') {
        const shop_id = window.localStorage.getItem('shop_id')
        if (!shop_id) {
          this.login()
        }
        action = this.$apiServer.get('api/v1/dashboard_web.json', {
          params: { shop_id }
        })
      } else {
        action = this.$apiServer.get('api/v1/dashboard_app.json')
      }

      action
        .then(response => {
          const data = response.data
          this.users = data.list.map(u => this.updateExtraInfo(u))
          this.workout_class = data.info
          if (this.workout_class.state == 1) {
            this.elapsedTime =
              this.workout_class.elapsed - this.workout_class.paused_time
          }
          this.ready = false
          if (this.type == 'app') {
            if (!this.wce_id) {
              this.$router.push({
                name: 'dashboard.app.id',
                params: { id: this.workout_class.id }
              })
            }
          }
        })
        .catch(() => {
          this.ready = true
        })
    },
    login() {
      this.$router.push({
        path: '/login',
        query: { redirect: this.$route.fullPath, save_shop: true }
      })
    },
    start() {
      this.$apiServer
        .post(`api/v1/control/${this.wce_id}/start.json`)
        .then(response => {
          this.workout_class = response.data
        })
    },
    pause() {
      this.$apiServer
        .post(`api/v1/control/${this.wce_id}/pause.json`)
        .then(response => {
          this.workout_class = response.data
        })
    },
    stop() {
      this.$apiServer
        .post(`api/v1/control/${this.wce_id}/stop.json`)
        .then(response => {
          this.workout_class = response.data
          this.$router.push({
            name: 'class.show',
            params: {
              id: response.data.workout_class
            }
          })
        })
        .catch(error => {
          console.error('Failed to cancel workout class', error);
          // Ensure navigation even if there's an error
          this.$router.push({
            name: 'class.show',
            params: { id: this.workout_class.workout_class }
          })
        })
    },
    cancel() {
      this.$apiServer
        .post(`api/v1/control/${this.wce_id}/cancel.json`)
        .then(response => {
          this.workout_class = response.data
          this.$router.push({
            name: 'class.show',
            params: { id: response.data.workout_class }
          })
        })
        .catch(error => {
          console.error('Failed to cancel workout class', error);
          // Ensure navigation even if there's an error
          this.$router.push({
            name: 'class.show',
            params: { id: this.workout_class.workout_class }
          })
        })
    },
    updateExtraInfo(user) {
      let classes = []
      let bgClass = ''
      if (!user.hr) {
        user.classes = ['inactive', 'grey']
        return user
      }
      if (user.since && user.since > 15) {
        classes = ['inactive']
      }
      let user_range = 1
      if (user.percent < 60) {
        bgClass = 'grey'
        user_range = 1
      } else if (user.percent < 70) {
        bgClass = 'blue'
        user_range = 2
      } else if (user.percent <= 84) {
        bgClass = 'green'
        user_range = 3
      } else if (user.percent <= 92) {
        bgClass = 'orange'
        user_range = 4
      } else {
        bgClass = 'red'
        user_range = 5
      }
      const goal_heartbeat_type = this.workout_class.goal_heartbeat_type
      const goal_heartbeat_value = this.workout_class.goal_heartbeat_value
      classes.push(bgClass)
      if (user_range == goal_heartbeat_type) {
        classes.push('best')
      } else if (goal_heartbeat_type == 9) {
        const start_range = goal_heartbeat_value - 5
        const end_range = goal_heartbeat_value + 5
        if (user.percent >= start_range && user.percent <= end_range) {
          classes.push('best')
        }
      }
      user.classes = classes

      let stand_value = 0
      if (goal_heartbeat_type == 2) {
        stand_value = 65
      } else if (goal_heartbeat_type == 3) {
        stand_value = 75
      } else if (goal_heartbeat_type == 4) {
        stand_value = 85
      } else if (goal_heartbeat_type == 5) {
        stand_value = 95
      } else if (goal_heartbeat_type == 9) {
        stand_value = goal_heartbeat_value
      }
      let bestLabel = null
      if (stand_value != 0) {
        const abs_value = Math.abs(stand_value - user.percent)
        if (abs_value < 6) {
          if (abs_value == 0) {
            bestLabel = 'PERFECT'
          } else if (abs_value <= 2) {
            bestLabel = 'GREAT'
          } else if (abs_value <= 5) {
            bestLabel = 'GOOD'
          }
        }
      }
      user.best_label = bestLabel
      return user
    },
    
  },
  mounted() {
    this.apiTickCounter = 0
    this.loadUsers()
    this.reloadInterval = setInterval(this.loadUsers, 1000)
  },
  destroyed() {
    this.reloadInterval && clearInterval(this.reloadInterval)
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .user-board {
    padding: 30px;
    @media (max-width: 540px) {
      padding: 15px;
    }
  }

  .el-col {
    padding-bottom: 20px;
    @media (max-width: 540px) {
      padding-bottom: 15px;
    }
    width: calc(100% / 6);
    @media (max-width: 1820px) {
      width: calc(100% / 5);
    }
    @media (max-width: 1550px) {
      width: calc(100% / 4);
    }
    @media (max-width: 1200px) {
      width: calc(100% / 3);
    }
    @media (max-width: 992px) {
      width: calc(100% / 2);
    }
    @media (max-width: 680px) {
      width: 100%;
    }
  }
}

.title-bar {
  display: flex;
  height: 80px;
  box-shadow: 0 -8px 10px 0 rgba(0, 0, 0, 0.13);
  background-color: white;
  font-size: 32px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.user-board {
  flex: 1;
}
.content-wrapper {
  display: flex;
}
.main-header {
  display: flex;
  flex-direction: row;
  height: 80px;
  background-color: #252525;
  position: relative;
  padding: 0 40px;

  .logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 40px;
    width: auto;
    margin: auto;
  }

  justify-content: space-between;
}
.btn {
  display: inline-block;
  width: 50px;
  height: 50px;
  appearance: none;
  border-radius: 25px;
  border: none;
  padding: 0;
  background: none no-repeat center;
  background-size: 50px 50px;

  &.btn-play {
    background-image: url('~@/assets/icons/btn-play.png');
  }
  &.btn-pause {
    background-image: url('~@/assets/icons/btn-pause.png');
  }
  &.btn-stop,
  &.btn-cancel {
    background-image: url('~@/assets/icons/btn-power.png');
  }
}
.actions {
  align-self: center;
  outline: none;
  > button.btn {
    cursor: pointer;
    &:focus {
      outline: none;
    }
    margin-right: 30px;
    font-size: 0;
    &:last-child {
      margin-right: 5px;
    }
  }
}
.logo-page {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  img.logo {
    width: 400px;
    height: auto;
  }
}
</style>
