<template>
  <div class="history-content" v-if="formType === 'history'">
    <div class="report-actions">
      <el-button @click="onDownloadExcel(historyDetail)" class="default">
        <span>다운로드</span><span class="icon el-icon-download" />
      </el-button>
      <el-button @click="onDelete(historyDetail)" class="default">
        <span>삭제</span><span class="icon el-icon-delete" />
      </el-button>
    </div>
    <div ref="reportContainer">
      <Report v-loading="!historyDetail" :detail="historyDetail"> </Report>
    </div>
  </div>
  <ResourceForm
    v-else-if="currentRealForm === 'individual'"
    :schema="schema"
    :id="id"
    @loadData="onFormLoad"
    @bulkRegister="switchToBulk"
  >
    <template #header="scope">
      <h2 v-if="formType === 'show'">
        {{ scope.form.name
        }}<span class="title-sub">{{ $t('님의 운동정보') }}</span>
      </h2>
      <h2 v-else>
        {{ scope.form.name }}
      </h2>
    </template>
    <h2 slot="empty">{{ $t('회원을 선택해주세요.') }}</h2>
    <h2 slot="new">{{ $t('새 회원 추가') }}</h2>

    <template #show="scope">
      <div class="member-show">
        <h3>{{ $t('개인정보') }}</h3>
        <div class="member-info">
          <div class="item">
            <span class="label">{{ $t('클래스') }}</span>
            <span class="value">{{ scope.form.class_name }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('이름 (성별)') }}</span>
            <span class="value">{{
              `${scope.form.name} (${
                scope.form.gender === 0 ? $t('MALE') : $t('FEMALE')
              })`
            }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('신장') }}</span>
            <span class="value">{{ scope.form.height + ' cm' }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('생년월일') }}</span>
            <span class="value">{{ scope.form.birth_day }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('체중') }}</span>
            <span class="value">{{ scope.form.weight + ' kg' }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('핸드폰') }}</span>
            <span class="value">{{ scope.form.phone }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('안정심박') }}</span>
            <span class="value">{{ scope.form.optimal_hr + ' bpm' }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('이메일') }}</span>
            <span class="value">{{ scope.form.email }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('최대심박') }}</span>
            <span class="value">{{
              (scope.form.max_hr || getMaxHr(scope.form.birth_day)) + ' bpm'
            }}</span>
          </div>
        </div>
        <div class="header-container">
          <h3>{{ $t('운동이력') }}</h3>
          <div class="actions">
            <el-button @click="deleteSelectedItems" class="default">
              <span>선택항목 삭제</span><span class="icon el-icon-delete" />
            </el-button>
          </div>
        </div>
        <div class="history">
          <div v-for="(h, i) in history" :key="i" class="history-item-wrapper">
            <div class="history-item-container">
              <input type="checkbox" v-model="selectedItems" :value="h.workout_class_execution" class="custom-checkbox" />
              <div
                class="history-item"
                @click="onClickHistoryItem(h.workout_class_execution)"
              >
                <div>{{ toStartedTime(h.started_at) }}</div>
                <div>{{ toRunningTime_3(h.running_time) }}</div>
                <div class="center">{{ h.class_name }}</div>
                <div class="right">{{ Math.round(h.calories/3) + 'kcal' }}</div>
                <div class="right">{{ Math.round(h.avg_hr) + 'bpm' }}</div>
                <div class="right">{{ h.max_hr + 'pbm' }}</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </template>
  </ResourceForm>
  <MultiResourceForm
    v-else
    @switchToIndividual="switchToIndividual"
    @switchToNormal="switchToNormal"
  />
</template>

<script>
import ResourceForm from '@/components/ResourceForm'
import MultiResourceForm from '@/components/MultiResourceForm'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import html2canvas from 'html2canvas';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Report from '@/components/Report'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MemberForm',
  mixins: [MixinFormatUtils],
  props: ['classes'],
  components: { Report, ResourceForm, MultiResourceForm },
  computed: {
    ...mapGetters(['user']),
    id() {
      return this.$route.params.id
    },
    formType() {
      return this.$route.meta && this.$route.meta.formType
    },
    wceId() {
      return this.$route.params.wce_id
    },
    currentRealForm() {
      if (this.currentForm === 'bulk') {
        if (this.formType !== 'new')
        return 'individual'
      }
      return this.currentForm
    }
  },
  watch: {
    wceId() {
      if (this.wceId) {
        this.loadHistoryDetail()
      }
    }
  },
  data() {
    const schema = {
      class_name: {
        label: this.$t('CLASSNAME'),
        type: 'string',
        option: {
          disabled: true
        }
      },
      name: {
        label: this.$t('NAME'),
        type: 'string',
        option: {
          disabled: true
        }
      },
      gender: {
        label: this.$t('GENDER'),
        type: 'radio',
        resource: 'workout_types',
        option: {
          0: this.$t('MALE'),
          1: this.$t('FEMALE')
        }
      },
      height: {
        label: this.$t('HEIGHT'),
        type: 'integer',
        option: {
          unit: 'cm'
        }
      },
      weight: {
        label: this.$t('WEIGHT'),
        type: 'integer',
        option: {
          unit: 'kg'
        }
      },
      birth_day: {
        label: this.$t('BIRTHDAY'),
        type: 'datetime'
      },
      optimal_hr: {
        label: this.$t('안정 심박수'),
        type: 'integer',
        option: {
          unit: 'bpm'
        }
      },
      max_hr: {
        label: this.$t('최대 심박수'),
        type: 'integer',
        option: {
          unit: 'bpm'
        }
      },
      email: {
        label: this.$t('EMAIL'),
        type: 'string',
        option: {
          disabled: true
        }
      },
      phone: {
        label: this.$t('휴대폰'),
        type: 'string',
        option: {
          disabled: true
        }
      }
    }
    const show = Object.assign({}, schema, {
      created_at: {
        label: this.$t('REGIST_DATE'),
        type: 'datetime'
      },
      classes: {
        label: this.$t('MATCHING_CLASS'),
        type: 'multi_select',
        option: {
          resource: 'classes'
        }
      }
    })
    return {
      historyDetail: null,
      history: [],
      selectedItems: [], // Array to keep track of selected items
      schema: {
        edit: schema,
        show: show
      },
      currentForm: 'individual'
    }
  },
  methods: {
    onFormLoad() {
      this.history = []
      this.$apiServer
        .get(`api/v1/members/${this.id}/history.json`)
        .then(response => {
          this.history = response.data
        })
        .catch(() => {
          this.$router.replace({ name: this.$route.meta.pathName })
        })
    },
    onClickHistoryItem(wceId) {
      this.$router.push({
        name: this.$route.meta.pathName + '.history.show',
        params: { wce_id: wceId, id: this.id }
      })
    },
    removeDuplicates(hrs) {
      const seen = new Set();
      const uniqueHrs = {
        time: [],
        hr: []
      };

      // hrs?.time?.forEach((time, index) => {
      //   const hr = hrs.hr[index];
      //   const key = `${time}-${hr}`;
      //   if (!seen.has(key)) {
      //     seen.add(key);
      //     uniqueHrs.time.push(time);
      //     uniqueHrs.hr.push(hr);
      //   }
      // });

      hrs?.time?.forEach((time, index) => {
        const hr = hrs.hr[index];
        const timeInSeconds = Math.floor(new Date(time).getTime() / 1000); // 초 단위로 변환
        const key = `${timeInSeconds}`;
        if (!seen.has(key)) {
          seen.add(key);
          uniqueHrs.time.push(new Date(timeInSeconds * 1000)); // 초 단위로 변환된 시간값을 다시 Date 객체로 변환하여 추가
          uniqueHrs.hr.push(hr);
        }
      });

      return uniqueHrs;
    },
    loadHistoryDetail() {
      this.historyDetail = null
      this.$apiServer
        .get(`api/v1/history/${this.wceId}/member/${this.id}/hr.json`)
        .then(response => {
          const data = response.data;
          const uniqueData = this.removeDuplicates(data.hrs);
          data.hrs = uniqueData;
          this.historyDetail = data
        })
        .catch(() => {
          this.$router.replace({ name: this.$route.meta.pathName })
        })
    },
    switchToBulk() {
      this.currentForm = 'bulk';
    },
    switchToIndividual() {
      this.currentForm = 'individual'
    },
    switchToNormal() {
      console.log('switchToNormal')
      this.currentForm = 'individual'
      this.$router.back()
      window.location.reload();
    },
    deleteSelectedItems() {
      if (this.selectedItems.length === 0)
      {
        this.$message({
          type: 'warning',
          message: this.$t('삭제할 항목을 선택해주세요.')
        });
        return;
      }
      this.$confirm(this.$t('선택한 운동이력을 정말 삭제 하시겠습니까?') + '<br>삭제할 항목 개수: ' + this.selectedItems.length, '운동이력 삭제', {
        confirmButtonText: this.$t('삭제'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true
      }).then(() => {
        const deletePromises = this.selectedItems.map(itemId => {
          return this.$apiServer.delete(`api/v1/history/${itemId}/member/${this.id}/delm.json`)
            .then(response => {
              console.log(`Deleted item with id: ${itemId}`, response);
              // Remove the item from history on successful deletion
              this.history = this.history.filter(h => h.workout_class_execution !== itemId);
            })
            .catch(error => {
              console.error(`Failed to delete item with id: ${itemId}`, error);
              // Optionally handle the error, e.g., show a notification to the user
            });
        });

        Promise.all(deletePromises).then(() => {
          // Clear the selection after all deletions are processed
          this.selectedItems = [];
        });
      });
    },
    hexToArgb(hex) {
      return `FF${hex.replace('#', '')}`;
    },
    getAge(birthDay) {
      return (
        moment()
          .startOf('year')
          .diff(moment(birthDay).startOf('year'), 'years') +
        1
      )
    },
    async onDownloadExcel(d) {
      // historyDetail이 없는 경우 데이터를 로드합니다.
      if (!this.historyDetail) {
        await this.loadHistoryDetail();
      }
      
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('개인운동이력(운동정보)');

      // 열 너비 설정
      ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'].forEach((col) => {
        worksheet.getColumn(col).width = 25.25;
      });
      ['J', 'K', 'L', 'M', 'N'].forEach((col) => {
        worksheet.getColumn(col).width = 19;
      });
      // 행 높이 설정
      for (let row = 2; row <= 8; row++) {
        worksheet.getRow(row).height = 42.5;
      }
      
      const endOfRowNum = 11 + d.hrs.time.length;
      for (let row = 10; row <= endOfRowNum; row++) {
        worksheet.getRow(row).height = 32.5;
      }

      // 헬퍼 함수: 셀 스타일 설정
      const setCellStyle = (cell, options = {}) => {
        cell.font = { size: 15, ...options.font };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true, ...options.alignment };
        if (options.fill) {
          cell.fill = options.fill;
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      };

      // 개인정보 섹션
      const personalInfoTitleCell = worksheet.getCell('B2');
      personalInfoTitleCell.value = '개인정보';
      setCellStyle(personalInfoTitleCell, {
        font: { color: { argb: 'FFFFFF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } }
      });
      const bgTitle = 'F2F2F2';
      const personalInfoHeaders = ['이름', '성별', '생년월일', '나이(year)', '신장(cm)', '체중(kg)', '안정 시 심박수(bpm)', '최대심박수(bpm)'];
      personalInfoHeaders.forEach((header, index) => {
        const cell = worksheet.getCell(3, index + 2);
        cell.value = header;
        setCellStyle(cell, {
          font: { bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgTitle } }
        });
      });

      // 회원정보 API 호출
      const personalInfoValues = [
        d.member.name,
        d.member.gender === 0 ? '남' : '여',
        d.member.birth_day,
        this.getAge(d.member.birth_day),
        d.member.height,
        d.member.weight,
        d.member.optimal_hr,
        d.member.max_hr === null || d.member.max_hr === 0 ? this.getMaxHr(d.member.birth_day) : d.member.max_hr
      ];
      personalInfoValues.forEach((value, index) => {
        const cell = worksheet.getCell(4, index + 2);
        cell.value = value;
        setCellStyle(cell);
      });

      // 운동이력 섹션
      const exerciseTitleCell = worksheet.getCell('B6');
      exerciseTitleCell.value = '운동이력';
      setCellStyle(exerciseTitleCell, {
        font: { color: { argb: 'FFFFFF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } }
      });

      worksheet.mergeCells('J6:N6');
      const exerciseIntensityCell = worksheet.getCell('J6');
      exerciseIntensityCell.value = '운동강도(%)';
      setCellStyle(exerciseIntensityCell, {
        font: { bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgTitle } }
      });

      const exerciseHeaders = [
        'No', '날짜', '운동시간', '클래스명', '소비칼로리(kcal)', '평균심박수(bpm)', '최고심박수(bpm)', '평균 운동강도(%)',
        '운동강도\r\n0~59(%)', '운동강도\r\n60~69(%)', '운동강도\r\n70~83(%)', '운동강도\r\n84~91(%)', '운동강도\r\n92~100(%)'
      ];
      var colorMap = [this.user.shop.level1_color, this.user.shop.level2_color, this.user.shop.level3_color, this.user.shop.level4_color, this.user.shop.level5_color]
      console.log("colorMap : ", colorMap);
      // ['A9A9A9', '87CEEB', '90EE90', 'FFA07A', 'FF6347']
      exerciseHeaders.forEach((header, index) => {
        const cell = worksheet.getCell(7, index + 2);
        cell.value = header;
        setCellStyle(cell, {
          font: { color: { argb: index < 8 ? '000000' : 'FFFFFF' }, bold: true },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: index < 8 ? bgTitle : this.hexToArgb(colorMap[index - 8]) }
          }
        });
      });

      console.log(d);
      const exerciseValues = [
        1, // No
        this.toStartedTimeFull(d.started_at), // 날짜
        this.toRunningTime_3(d.running_time), // 운동시간
        d.class_name, // 클래스명
        Math.round(d.calories/3), // 소비칼로리(kcal)
        Math.round(d.avg_hr), // 평균심박수(bpm)
        d.max_hr, // 최고심박수(bpm)
        d.avg_per, // 평균 운동강도(%)
        Math.round(d.range1_time / d.running_time * 100), // 운동강도 0~59(%)
        Math.round(d.range2_time / d.running_time * 100), // 운동강도 60~69(%)
        Math.round(d.range3_time / d.running_time * 100), // 운동강도 70~83(%)
        Math.round(d.range4_time / d.running_time * 100), // 운동강도 84~91(%)
        Math.round(d.range5_time / d.running_time * 100) // 운동강도 92~100(%)
      ];
      exerciseValues.forEach((value, index) => {
        const cell = worksheet.getCell(8, index + 2);
        cell.value = value;
        setCellStyle(cell, {
          font: { color: { argb: index < 8 ? '000000' : 'FFFFFF' } },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: index < 8 ? '' : this.hexToArgb(colorMap[index - 8]) }
          }
        });
      });

      const exerciseInfoCell = worksheet.getCell('B10');
      exerciseInfoCell.value = '운동이력';
      setCellStyle(exerciseInfoCell, {
        font: { color: { argb: 'FFFFFF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } }
      });

      const exerciseInfoHeaders = ['운동시간', '심박수(bpm)', '운동강도(%)'];
      exerciseInfoHeaders.forEach((header, index) => {
        const cell = worksheet.getCell(11, index + 2);
        cell.value = header;
        setCellStyle(cell, {
          font: { bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgTitle } }
        });
      });

      const startTime = new Date(d.hrs.time[0]).getTime();
      const exerciseInfoValues = d.hrs.time.map((time, index) => {
        const elapsedTime = new Date(new Date(time).getTime() - startTime).toISOString().substr(11, 8); // HH:mm:ss 포맷
        return [
          elapsedTime,
          d.hrs.hr[index],
          { formula: `C${index + 12}/$I$4*100` } // 운동강도(%) 수식 추가
        ];
      });
      exerciseInfoValues.forEach((values, index) => {
        values.forEach((value, colIndex) => {
          const cell = worksheet.getCell(index + 12, colIndex + 2);
          if (typeof value === 'object' && value.formula) {
            cell.value = { formula: value.formula };
            cell.numFmt = '0.0'; // 소수점 첫째 자리까지만 표시
          } else {
            cell.value = value;
          }
          setCellStyle(cell);
        });
      });


      // Capture the visible content as an image
      const reportContainer = this.$refs.reportContainer;
      const canvas = await html2canvas(reportContainer);
      const imgData = canvas.toDataURL('image/png');

      // Add the image to the worksheet
      const imageId = workbook.addImage({
        base64: imgData,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: { col: 5, row: 11 },
        ext: { width: canvas.width, height: canvas.height },
      });

      // 기본 줌 설정
      worksheet.views = [{ state: 'normal', zoomScale: 70 }];

      // Generate Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, `${d.name || 'download'}.xlsx`);
    },
    onDelete(d) {
      this.$confirm(this.$t('현재 운동이력을 정말 삭제 하시겠습니까?'), '운동이력 삭제', {
        confirmButtonText: this.$t('삭제'),
        cancelButtonText: this.$t('취소'),
        type: 'warning',
        center: true
      }).then(() => {
        let memberId = (typeof d.member === 'object' && d.member !== null && 'id' in d.member) ? d.member.id : d.member;
        this.$apiServer
          .delete(`api/v1/history/${d.workout_class_execution}/member/${memberId}/delm.json`)
          .then(() => {
            this.$message({
              type: 'success',
              message: this.$t('운동이력이 삭제되었습니다.')
            })
            this.$router.push({ path: `/member/${memberId}` });
          })
      })

    }
  },
  mounted() {
    if (this.formType === 'history' && this.wceId) {
      this.loadHistoryDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';
$max-width: 800px;

.el-form {
  max-width: $max-width;
  border: 1px solid $--border-color-base;
  padding: 20px;
  border-radius: 4px;
  .el-input {
  }
}

.title-sub {
  font-size: 20px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.member-info {
  padding: 20px 12px;
  margin-left: -10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: solid 1px $light-grey;
  width: calc(100% + 10px);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    display: flex;
    font-size: 16px;
    > .label {
      width: 100px;
      color: $text-grey;
    }
    > .value {
      flex-grow: 1;
    }
    margin-bottom: 18px;
  }
}

@include history-list;

.header-container {
  display: flex;
  align-items: flex-start; /* Align items to the top */
}

.header-container h3 {
  margin-right: 20px; /* Adjust the spacing as needed */
}

.actions {
  display: flex;
  align-items: flex-start; /* Align button to the top */
}

.el-button.default {
  margin-top: 0; /* Remove any margin that might affect alignment */
}

.history-item-container {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  width: 20px; /* Increase the size of the checkbox */
  height: 20px; /* Increase the size of the checkbox */
  margin-right: 10px; /* Add space between the checkbox and the history-item div */
}

.history-item {
  flex-grow: 1;
  cursor: pointer;
}

.report-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .el-button {
    margin-left: 10px;
  }
}
</style>