<template>
  <el-container direction="vertical">
    <div class="title-wrapper">
      <div class="title">
        {{ $t('이력 관리') }}
      </div>
      <div class="description">
        {{ $t('회원들의 운동 내역을 확인하실 수 있습니다.') }}
      </div>
    </div>
    <el-container direction="horizontal">
      <el-aside width="470px">
        <el-row class="actions">
          <el-button @click="onToday" class="full" type="primary">
            {{ $t('가장 최근 보기') }}
          </el-button>
          <!-- :min-date="minDate" :max-date="maxDate" -->
          <v-date-picker ref="calendar" :min-date="minDate" :max-date="maxDate" v-model="date" mode="single" :masks="{ title: 'YYYY년 MMM' }" :available-dates="availableDates" is-inline/>
        </el-row>
      </el-aside>
      <el-main>
        <div class="content">
          <h2>{{date.toLocaleDateString()}}</h2>
          <ClassReport :detail="wc" v-for="wc in listFiltered" :key="wc.id" />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import moment from 'moment'
import ClassReport from '@/components/ClassReport'

import { ref } from 'vue'

const calendar = ref(null);

export default {
  name: 'HistoryPage',
  components: { ClassReport },
  computed : {
    listFiltered() {
      return this.classList.filter(h => h.avg_hr)
    }

  },
  data() {
    return {
      classList: [],
      minDate: null,
      maxDate: null,
      availableDates: [],
      //availableDates: [],
      date: new Date()
    }
  },
  watch: {
    date() {
      this.loadHistory()
    }
  },
  methods: {
    onToday() {
      // this.date = new Date(); //this.availableDates.length > 0 ? new Date(this.availableDates[this.availableDates.length - 1]) : new Date()
      // this.minDate = null; //this.availableDates.length > 0 ? new Date(this.availableDates[0]) : null
      // this.maxDate = null; //this.availableDates.length > 0 ? new Date(this.availableDates[this.availableDates.length - 1]) : null

      // this.date = this.availableDates.length > 0 ? new Date(Math.max(...this.availableDates)) : new Date()
      // this.minDate = this.availableDates.length > 0 ? new Date(Math.min(...this.availableDates)) : null
      // this.maxDate = this.availableDates.length > 0 ? new Date(Math.max(...this.availableDates)) : null      

      // console.log("Math.max(...this.availableDates):", this.availableDates.reduce((max, date) => (date > max ? date : max), this.availableDates[0]))
      this.date = this.availableDates.length > 0 ? new Date(this.availableDates.reduce((max, date) => (date > max ? date : max), this.availableDates[0])) : new Date()
      // this.minDate = this.availableDates.length > 0 ? new Date(this.availableDates.reduce((min, date) => (date < min ? date : min), this.availableDates[0])) : null
      //this.maxDate = this.availableDates.length > 0 ? this.date : null
      console.log("calendar:", calendar)
      //calendar.value.move(this.date)
      //calendar.updateValue(this.date);
      console.log("this.date:", this.date)
    },
    loadHistory() {
      this.classList = []
      this.$apiServer
        .get(`api/v1/history.json`, {
          params: { day: moment(this.date).format('YYYY-MM-DD') }
        })
        .then(response => {
          console.log("response.data:"+JSON.stringify(response.data))
          this.classList = response.data
        })
    },
    loadEnabledDates() {
      this.availableDates.splice(0, -1)
      this.$apiServer
        .get(`api/v1/history_alldate.json`, {
          params: { day: moment(this.date).format('YYYY-MM-DD') }
        })
        .then(response => {
          console.log("response.data.unique_dates:", response.data.unique_dates)
          this.availableDates.push(...response.data.unique_dates)
          console.log("availableDates:", this.availableDates)
          this.onToday()
        })
    },
  },
  mounted() {
    this.loadEnabledDates()
    this.loadHistory()
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

@include title-wrapper;
.el-container {
  flex: 1;
  overflow: hidden;
  .el-aside {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .actions {
      padding: 0 15px;
      .el-button {
        font-size: 16px;
        height: 60px;
        width: 100%;
        border-radius: 30px;
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
        margin-bottom: 50px;
      }
    }
    ::v-deep {
      .vc-container {
        width: 100%;
        --day-content-width: 33px;
        --day-content-height: 33px;
        --day-content-margin: 7.5px;
        --day-content-bg-color-hover: rgba(251, 230, 230, 0.5);
        --day-content-bg-color-focus: rgba(251, 230, 230, 0.5);
        --header-padding: 20px;
        --arrows-padding: 18px;
        --highlight-height: 33px;
        font-family: 'Noto Sans Korean', sans-serif;
        border: none;
        border-radius: 20px;
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);

        .vc-header {
        }
        .vc-weekday {
          font-size: 16px;
          font-weight: normal;
          color: #333333;
          border-bottom: 1px solid #dddddd;
        }

        .vc-highlight.vc-rounded-full {
          background-color: #d60003;
        }
        .vc-day-content {
          font-size: 16px;
          font-weight: normal;
          height: 55px;
        }
      }
    }
  }
}

.el-main > .content {
  display: flex;
  flex-direction: column;
  padding: 30px 50px 60px;
  border-radius: 20px;
  background-color: #ffffff;
  h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}


</style>
