<template>
  <div class="detail">
    <div class="header">
      <h2 class="title">새 회원 일괄등록</h2>
      <el-button @click="downloadTemplate" class="default individual-register-button">
        <span>일괄등록 엑셀 양식 샘플 다운로드</span>
      </el-button>
      <el-button @click="switchToIndividual" class="default individual-register-button">
        <span>개별등록으로 변경</span>
      </el-button>
    </div>
    <el-input
      type="textarea"
      rows="20"
      :placeholder="placeholderText"
      v-model="bulkData"
    ></el-input>
    <div class="text-right">
      <el-button icon="el-icon-check" type="primary" @click="onSave">저장</el-button>
      <el-button icon="el-icon-close" type="info" @click="onCancel">취소</el-button>
    </div>
  </div>
</template>

<script>
import ExcelJS from 'exceljs';

export default {
  name: 'MultiResourceForm',
  data() {
    return {
      bulkData: '',
      placeholderText: `회원 일괄등록 절차
1. 일괄등록 엑셀 양식 샘플을 다운로드 합니다.
2. 일괄등록할 회원 정보를 엑셀 양식 샘플에 데이터 형식에 맞게 입력합니다.
3. 엑셀에서 헤더를 포함한 입력한 내용을 Ctrl + A 로 모두 선택합니다.
4. 선택된 내용을 Ctrl + C로 클립 보드에 복사합니다.
5. 여기 입력박스에 Ctrl + V 하여 엑셀에서 복사한 내용을 붙여넣기 해주세요.`
    };
  },
  methods: {
    switchToIndividual() {
      this.$emit('switchToIndividual');
    },
    switchToNormal() {
      this.$emit('switchToNormal');
    },
    async downloadTemplate() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('회원 일괄등록');

      worksheet.columns = [
        { header: '클래스', key: 'className', width: 15 },
        { header: '이름', key: 'name', width: 15 },
        { header: '성별', key: 'gender', width: 10 },
        { header: '키', key: 'height', width: 10 },
        { header: '몸무게', key: 'weight', width: 10 },
        { header: '생년월일', key: 'birthdate', width: 15 },
        { header: '안정심박수', key: 'restingHeartRate', width: 15 },
        { header: '최대심박수', key: 'maxHeartRate', width: 15 },
        { header: '이메일', key: 'email', width: 25 },
        { header: '휴대폰', key: 'phone', width: 15 }
      ];

      // Apply header style
      worksheet.getRow(1).eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' } // Yellow color
        };
        cell.font = {
          bold: true
        };
      });

      // Add sample data
      worksheet.addRow({
        className: '1반',
        name: '홍길동',
        gender: '남',
        height: 180.3,
        weight: 80.1,
        birthdate: '1990-01-01',
        restingHeartRate: 80,
        maxHeartRate: 130,
        email: 'hong@ehealth-one.com',
        phone: '010-1234-5678'
      });

      worksheet.addRow({
        className: '2반',
        name: '홍길녀',
        gender: '여',
        height: 170.5,
        weight: 58.5,
        birthdate: '1990-01-02',
        restingHeartRate: 80,
        maxHeartRate: 130,
        email: 'hong2@ehealth-one.com',
        phone: '010-9012-3456'
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = '회원 일괄등록 샘플.xlsx';
      link.click();
      URL.revokeObjectURL(link.href);
    },
    escapeHtml(text) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      };
      return text?.replace(/[&<>"']/g, function(m) { return map[m]; });
    },
    async onSave() {
      const lines = this.bulkData.trim().split('\n');
      const headers = lines[0].split('\t');
      const members = lines.slice(1).map((line, index) => {
        const data = line.split('\t');
        return headers.reduce((obj, header, idx) => {
          obj[header] = this.escapeHtml(data[idx]);
          obj['lineNumber'] = index + 2; // Line number in the input
          return obj;
        }, {});
      });

      const failedMembers = [];
      let successCount = 0;

      for (const member of members) {
        try {
          const response = await this.$apiServer.post(`api/v1/members.json`, {
            class_name: member['클래스'],
            name: member['이름'],
            gender: member['성별'] === '남' ? 0 : 1,
            height: parseFloat(member['키']),
            weight: parseFloat(member['몸무게']),
            birth_day: member['생년월일'],
            resting_heart_rate: parseInt(member['안정심박수'], 10),
            max_heart_rate: parseInt(member['최대심박수'], 10),
            email: member['이메일'],
            phone: member['휴대폰']? member['휴대폰'] : ''
          });
          if (response.status === 201) {
            successCount++;
            console.log(`Member ${member['이름']} registered successfully`);
          } else {
            console.log(`Failed to register member ${member['이름']}`);
            failedMembers.push(`[${member['lineNumber']}번째 줄] [${member['클래스']}] [${member['이름']}]`);
          }
        } catch (error) {
          console.error(`Error registering member ${member['이름']}:`, error);
          failedMembers.push(`[${member['lineNumber']}번째 줄] [${member['클래스']}] [${member['이름']}]`);
        }
      }

      const message = `
        회원 일괄등록 결과는 다음과 같습니다.<br/>
        성공 건수: ${successCount}<br/>
        실패 건수: ${failedMembers.length}<br/>
        ${failedMembers.length > 0 ? `<br/>실패한 회원 목록:<br/>${failedMembers.join('<br/>')}` : ''}
      `;

      this.$alert(message.trim(), '등록 결과', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '확인',
        callback: () => {
          this.switchToNormal();
        }
      });
    },
    onCancel() {
      // Implement cancel functionality
      console.log('Cancel button clicked');
      this.switchToIndividual();
    }
  }
};
</script>

<style scoped>
.detail {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px 50px 60px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 25px;
  line-height: 37px;
  font-weight: normal;
}

.individual-register-button {
  margin-left: auto;
}

.download-link {
  display: block;
  margin-bottom: 20px;
  color: #409eff;
  cursor: pointer;
}

.text-right {
  text-align: right;
  margin-top: 20px;
}
</style>