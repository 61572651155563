<template>
  <ResourcePage
    :query="query"
    :search-keyword="search"
    :search-keys="['name', 'email']"
    width="620px"
  >
    <template #title>
      {{ $t('MENU_MEMBER') }}
    </template>
    <template #description>
      {{ $t('회원을 선택하시면 운동내역을 확인하실 수 있습니다.') }}
    </template>
    <template #filter>
      <el-input
        :placeholder="$t('NAME_EMAIL_SEARCHING')"
        suffix-icon="el-icon-search"
        v-model="search"
      >
      </el-input>
    </template>
    <template #sort-buttons="{ sortList, sortKey, sortOrder }">
      <el-button @click="sortList('class_name')">
        클래스명
        <i v-if="sortKey === 'class_name'" :class="sortOrder === 'asc' ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </el-button>
      <el-button @click="sortList('name')">
        이름
        <i v-if="sortKey === 'name'" :class="sortOrder === 'asc' ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </el-button>
      <el-button @click="sortList('gender')">
        성별
        <i v-if="sortKey === 'gender'" :class="sortOrder === 'asc' ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </el-button>
      <el-button @click="sortList('birth_day')">
        나이
        <i v-if="sortKey === 'birth_day'" :class="sortOrder === 'asc' ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </el-button>
      <el-button @click="sortList('height')">
        키
        <i v-if="sortKey === 'height'" :class="sortOrder === 'asc' ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </el-button>
      <el-button @click="sortList('weight')">
        몸무게
        <i v-if="sortKey === 'weight'" :class="sortOrder === 'asc' ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </el-button>
    </template>
    <template #list-item="scope">
      <div class="list-item">
        <span v-html="scope.row.class_name || '클래스<br>없음'" />
        <span v-html="toHighlight(search, scope.row.name)" />
        <span v-html="scope.row.gender === 0 ? $t('MALE') : $t('FEMALE')" />
        <span v-html="getAge(scope.row.birth_day)" />
        <span v-html="scope.row.height + 'cm'" />
        <span v-html="scope.row.weight + 'kg'" />
      </div>
    </template>
    <template #detail>
      <MemberForm />
    </template>
  </ResourcePage>
</template>

<script>
import MemberForm from '@/views/pages/MemberForm'
import ResourcePage from '@/components/ResourcePage'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import moment from 'moment'

export default {
  name: 'MemberPage',
  mixins: [MixinFormatUtils],
  components: { ResourcePage, MemberForm },
  props: ['id', 'schema'],
  data() {
    return {
      query: {
        has_class: null
      },
      search: ''
    }
  },
  methods: {
    loadOptions() {},
    getAge(birthDay) {
      return (
        moment()
          .startOf('year')
          .diff(moment(birthDay).startOf('year'), 'years') +
        1 +
        this.$t('세')
      )
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style scoped lang="scss">
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    flex-grow: 66;
    flex-basis: 30px;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
      flex-grow: 50;
    }

    &:nth-child(3) {
      flex-grow: 100;
    }

    &:nth-child(4) {
      flex-grow: 70;
    }
  }
}
</style>