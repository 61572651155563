<script>
import { Bar } from 'vue-chartjs'
// import moment from 'moment'

function colorize(maxHr, ctx) {
  if (!ctx || !ctx.dataset || !ctx.dataset.data || !ctx.dataset.data[ctx.dataIndex]) {
   return '#949494'; // Default color if data is undefined
  }  
  const v = (ctx.dataset.data[ctx.dataIndex].y / maxHr) * 100
  const c =
    v >= 92
      ? ((this.colorMap && this.colorMap[4]) || '#bf2132')
      : v >= 84
      ? ((this.colorMap && this.colorMap[3]) || '#f49537')
      : v >= 70
      ? ((this.colorMap && this.colorMap[2]) || '#90c56a')
      : v >= 60
      ? ((this.colorMap && this.colorMap[1]) || '#7ab9f2')
      : ((this.colorMap && this.colorMap[0]) || '#949494')

  return c
}

// eslint-disable-next-line no-unused-vars
export default {
  name: 'HrChart',
  extends: Bar,
  render(createElement) {
    return createElement('canvas', {
      attrs: {
        id: this.chartId
      },
      ref: 'canvas'
    })
  },
  props: {
    maxHr: {
      type: Number
    },
    beginAtZero: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    },
    colors: null,
    colorMap: null,
    onClick: null
  },
  mounted() {
    if (!this.chartData) {
      return
    }

    const baseTime = Date.parse(this.chartData.data.time[0])
    const hrData = this.chartData.data.hr.map((h, i) => ({
      x: Date.parse(this.chartData.data.time[i]) - baseTime,
      y: h
    }))

    let i
    let tmp = -1
    let filtered = []
    for (i = 0; i < hrData.length; i++) {
      if (tmp == hrData[i].x) {
        // window.console.log('same ' + i)
      } else {
        filtered.push(hrData[i])
      }
      tmp = hrData[i].x
    }
    const data = {
      datasets: [
        {
          data: filtered,
          type: 'bar',
          pointRadius: 0,
          fill: false,
          lineTension: 0,
          borderWidth: 2,
          showLine: false // disable for a single dataset
        }
      ]
    }

    console.log("this.colorMap:"+this.colorMap)
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      elements: {
        rectangle: {
          backgroundColor: colorize.bind(this, this.maxHr),
          borderColor: colorize.bind(this, this.maxHr),
          borderWidth: 2
        }
      },
      animation: {
        duration: 0 // general animation time
      },
      hover: {
        animationDuration: 0 // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0, // animation duration after a resize
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'minute',
              tooltipFormat:'mm:ss',
              displayFormats: { minute: 'mm:ss' },
            },
            ticks: {
              minRotation: 30,
              maxRotation: 30
            }
          }
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 220,
              beginAtZero: true
            }
          }
        ]
      },
      onClick () {
        if (this.onClick) {
          this.onClick()
        }
      },
    }
    this.renderChart(data, options)
    this.$refs.canvas.addEventListener('click', this.onClick)
  }
}
</script>

<style scoped></style>
