<template>
  <el-container direction="vertical">
    <div class="title-wrapper">
      <div class="title">
        <slot name="title" />
      </div>
      <div class="description">
        <slot name="description" />
      </div>
    </div>
    <el-container direction="horizontal">
      <el-aside :width="width">
        <el-row class="actions">
          <el-form label-position="top" v-if="$slots.filter">
            <slot name="filter" :changeHandler="onFilterChange" />
          </el-form>
          <el-button
            v-if="$slots.filter"
            type="primary"
            circle
            icon="el-icon-plus"
            @click="onNew"
          />
          <el-button v-else @click="onNew" class="full">
            <i class="icon el-icon-plus" /><span>{{ $t('NEW_ITEM') }}</span>
          </el-button>
        </el-row>
        <div class="sort-buttons">
          <slot name="sort-buttons" :sortList="sortList" :sortKey="sortKey" :sortOrder="sortOrder" />
        </div>
        <div class="list">
          <div class="table-wrap">
            <el-table
              width="auto"
              height="100%"
              :loading="listLoading"
              highlight-current-row
              @current-change="handleCurrentChange"
              :show-header="false"
              :data="filteredList"
              @row-click="onSelectItem"
            >
              <el-table-column>
                <template slot-scope="scope" name="list-item">
                  <slot name="list-item" :row="scope.row" />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-aside>
      <el-main>
        <div class="content">
          <slot name="detail" />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'ResourcePage',
  props: {
    query: Object,
    searchKeyword: String,
    searchKeys: Array,
    width: {
      type: String,
      default: '450px'
    }
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.loadList()
      }
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    resource() {
      return this.$route.meta && this.$route.meta.resource
    },
    filteredList() {
      let list = this.listData || [];
      if (this.searchKeyword) {
        list = list.filter(data => {
          let text = '';
          this.searchKeys.forEach(v => (text += data[v]));
          return (
            text
              .toString()
              .toLowerCase()
              .indexOf(this.searchKeyword.toString().toLowerCase()) !== -1
          );
        });
      }
      if (this.sortKey) {
        list = list.sort((a, b) => {
          let result = 0;
          const aValue = a[this.sortKey];
          const bValue = b[this.sortKey];

          if (aValue === null) return 1; // a가 null이면 a가 뒤로
          if (bValue === null) return -1; // b가 null이면 b가 뒤로

          if (aValue < bValue) result = -1;
          if (aValue > bValue) result = 1;
          return this.sortOrder === 'asc' ? result : -result;
        });
      }
      return list;
    }
  },
  data() {
    return {
      listLoading: true,
      listData: null,
      currentRow: null,
      sortKey: null,
      sortOrder: 'asc'
    }
  },
  methods: {
    loadList() {
      this.listLoading = true
      this.$apiServer
        .get(`api/v1/${this.resource}.json`, { params: this.query })
        .then(response => {
          this.listData = response.data
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    onNew() {
      this.$router.push({ name: this.$route.meta.pathName + '.new' })
    },
    // eslint-disable-next-line no-unused-vars
    onSelectItem(row, column, event) {
      const pathName = this.$route.meta.pathName;
      const newPathName = pathName.endsWith('.new') ? pathName.slice(0, -4) : pathName;
      this.$router.push({
        name: newPathName + '.show',
        params: { id: row.id }
      })
    },
    onResourceUpdated() {
      this.loadList()
    },
    onFilterChange(query) {
      this.query = query
      this.loadList()
    },
    sortList(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
    }
  },
  mounted() {
    this.loadList()
  },
  created() {
    this.$eventBus.$on('resourceUpdated', this.onResourceUpdated)
  },
  destroyed() {
    this.$eventBus.$off('resourceUpdated', this.onResourceUpdated)
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

@include title-wrapper;
.el-row.actions {
  display: flex;
  padding: 0 15px;
  margin-bottom: 35px;
  ::v-deep .el-form {
    display: flex;
    flex: 1 0;
    align-items: center;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-input {
      input {
        border: none;
        background: none;
      }
    }
  }
  ::v-deep .el-input.el-input--suffix {
    .el-input__suffix-inner {
      .el-input__icon {
        font-size: 20px;
        color: $text-color;
      }
    }
  }
  .el-form {
    height: 60px;
    padding: 0 10px;
    border-radius: 30px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: flex;
    margin-right: 20px;
  }
  .el-button--primary.is-circle {
    padding: 20px;
  }
  ::v-deep .el-button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    flex-shrink: 0;
    font-size: 16px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
    line-height: 24px;
    border: none;
    &:focus,
    &:hover {
      //background-color: white;
    }

    > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    [class^='el-icon-'],
    [class*=' el-icon-'] {
      font-size: 20px;
    }
    &.full {
      .icon {
        margin-right: 15px;
      }
      flex-grow: 1;
    }
  }
}
.el-container {
  overflow: hidden;
  /deep/ .el-aside {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .list {
      position: relative;
      > .table-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      flex-grow: 1;
      margin-bottom: 4px;
      .highlight {
        background-color: #edff74;
        color: #1850ff;
      }
    }
    .el-input,
    .el-select {
      width: 100%;
    }
    .el-form-item {
      margin-bottom: 4px;
    }
    .el-table {
      height: 100%;
      &:before {
        content: none;
      }
    }
  }
}

/deep/ .el-form--label-top {
  .el-form-item__label {
    line-height: normal;
    padding: 0;
    padding-left: 4px;
  }
}
.actions {
  .el-button {
    width: 100%;
  }
}
.el-main {
  > .content {
    display: flex;
  }
}
/deep/ {
  .el-select-dropdown__item.with-icon {
    > * {
      vertical-align: middle;
    }
  }

  .img-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    margin-right: 10px;
    &.small {
      width: 16px;
      height: 16px;
    }
  }

  .list-item {
    & > * {
      vertical-align: middle;
    }
  }

  .list .table-wrap {
    overflow: visible;
    .el-table {
      overflow-y: auto;
      padding: 0 15px;
      background-color: transparent;
      .cell {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
    table {
      overflow: visible;
      border-spacing: 0 15px;
      width: auto !important;
      tr {
        background-color: white;
        height: 60px;
        border-radius: 30px;
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
        td {
          cursor: pointer;
          border-bottom: none;
          &:first-child {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
          }
          &:last-child {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }
      }
    }
  }
}
.sort-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .el-button {
    margin: 0 5px;
  }
}
</style>